import { useContext } from "react"
import { Redirect } from "react-router-dom"

import SessionContext from "../context/sessionContext"


const PrivateRoute = ({children}) => {
    const context = useContext(SessionContext)
    return context.tokenExists() ? children : <Redirect to="/login" />
}

export default PrivateRoute;