import React, { Component, Fragment } from "react";

import './DateTable.css'
import { Table } from "react-bootstrap";
import RowDataTable from "./RowDataTable";


class DataTable extends Component {

    buttonClick = (id, action, item) => {
        item.action = action
        this.props.action&&this.props.action(id, action, item)
    }

    render() {

        try{
            this.nameColumns = this.props.columns
        }catch(e){
            this.nameColumns = []
        }

        return (

            <Fragment>

                <div className="table-max">
                    <Table className="table-striped table-responsive">

                        <thead>
                            <tr>

                                {
                                    this.nameColumns?.map((item, index) => <th scope="col" id={item.field}>{item.title}</th>)

                                }
                                <th scope="col" id="action" >Actions</th>
                            </tr>
                        </thead>


                        <tbody>

                            {
                                

                                this.props.data?.map((item, index) =>
                                (

                                    <RowDataTable permission={this.props.permission}
                                        id={item.client_id} columns={this.nameColumns}
                                        item={item} action={this.buttonClick}
                                        stations={this.props.stations&&this.props.stations}
                                    />

                                )

                                )
                            }


                        </tbody>
                    </Table>
                </div>


            </Fragment>

        )

    }

}


export default DataTable;
