import React, { Component } from 'react'
import { LoginService } from "../Services/loginService";


const SessionContext = React.createContext(false)


export class SessionProvider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {
                access: []
            },
            token: "",
            cart: {},
            isReady: false,
            login: false
        }

        this.handlerLogin = this.handlerLogin.bind(this)

    }

    componentDidMount() {
        const token = window.localStorage.getItem('token_id')
        if (token !== null) {
            this.handlerLogin(token)
        }   
    }

    componentDidUpdate(){
        
    }
   

    handlerLogin(token) {

        const data = new FormData();
        data.append("token", token)

        const options = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: data
        }
        LoginService().login(`${process.env.REACT_APP_HOST}auth/user-data`, options)
            .then(data => {
                if (!data.response) throw new Error("data is undefined 0x234")
                return data
            })
            .then(data => {
                if (data.response) {
                    this.handlerUserData(data.user)
                }
            })
            .catch(error => {
                this.logout()
            })
    }

    logout = () => {
        const item = window.localStorage.getItem('token_id')
        if(item != null){
            localStorage.removeItem('token_id')
            this.setState({login:false})
        }
    }

    handlerUserData = (data) => {
        this.setState({
            user: data,
            token: data.token,
            isReady: true,
            login: true
        })
    }

    getToken = () => {
        const token = window.localStorage.getItem('token_id')
        return token || "";
    }

    tokenExists = () => {
        const token = window.localStorage.getItem('token_id')
        return token !== null; 
    }

    render() {
        return (
            <SessionContext.Provider value={{
                handlerUserData: this.handlerUserData,
                logout: this.logout,
                getToken:this.getToken,
                tokenExists:this.tokenExists,
                user: this.state.user,
                token: this.state.token,
                login: this.state.login
            }}>
                {this.props.children }

            </SessionContext.Provider>
        )
    }


}



export const SessionConsumer = SessionContext.Consumer

export default SessionContext