import { Modal } from "react-bootstrap"



const DialogDetail = (props) => {


    const onHide = () => {
        props.onHide && props.onHide()
    }

    const data = props.data ? Object.keys(props.data) : []

    const hidden = ["pass", "token", "img", "action", "status"]

    return (


        <>

            <Modal show={props.show && props.show} centered="true" onHide={onHide} >

                <Modal.Header closeButton="true">

                </Modal.Header>

                <Modal.Body>

                    <ul className="detail">
                        {
                            data.map(item => {
                                    return !hidden.includes(item) && <li><span>{item}</span>{props.data[item] instanceof Object ? "" : props.data[item]}</li>;
                            })
                        }

                    </ul>

                </Modal.Body>



            </Modal>



        </>

    )

}


export default DialogDetail;
