import DataTable from "../DataTable"
import { Button } from "react-bootstrap"
import { FaUserPlus } from "react-icons/fa"



export const OperatorList = (props) => {


    const buttonNew = (e) => {
        props.buttonNew()
    }


    return(
        <>
            <div className="card-content">
                <h2 className="d-flex justify-content-between" >Operadores  
                    { props.permission&&props.permission >=4 ?  <Button className="m-0" size="sm" onClick={buttonNew}>Nuevo <FaUserPlus/></Button> : "" }  
                </h2>

                <DataTable action={props.action} data={props.data} columns={props.columns} permission={props.permission} />

            </div>
        </>
    )


}