import React, { Component } from 'react';
import { Alert, Button, Form} from "react-bootstrap";

import { LoginService } from '../Services/loginService';
import SessionContext from "../context/sessionContext";
import { BsDropletFill } from 'react-icons/bs';
import { Loading } from './Loading';



class Login extends Component {


    constructor(props) {
        super(props);

        this.state = {
            formControl: {
                user: "",
                pass: ""
            },
            formErrors: {},
            isLoading: false,
            alertMessage: "",
            alertOpen: false,
            errorVariant: "success",
            buttonBlock: true
        }


        this.handlerSubmit = this.handlerSubmit.bind(this);
        this.handlerChange = this.handlerChange.bind(this);
        this.handlerBlur = this.handlerBlur.bind(this);


        this.regexUser = /^[A-Za-z]([A-Za-z0-9]+){4,18}$/;
        this.regexPass = /^([A-Za-z0-9@$!%*?&.]){5,18}$/;
        this.regexPass1 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,18}$/;

    }

    componentDidMount() {

        if (this.context.login) {
            this.props.history.push('/')
        }

    }

    componentDidUpdate() {
        if (this.context.login) {
            this.props.history.push('/')
        }
    }


    handlerSubmit(e) {
        e.preventDefault();

        this.setState({ isLoading: true });

        const form = new FormData();
        form.append('user', this.state.formControl.user);
        form.append('pass', this.state.formControl.pass);

        let options = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: form
        };


        LoginService().login(`${process.env.REACT_APP_HOST}auth/login`, options)
            .then(data => {

                if (!data.response) throw new Error("Usuario no válido")

                if (data.response) {
                    localStorage.setItem('token_id', data.user.token);

                    this.setState({ alertMessage: "Login success!!!", errorVariant: "success", alertOpen: true });

                    this.context.handlerUserData(data.user);

                    this.props.history.push('/');

                }


            })
            .catch(error => {

                this.showAlert("danger", error.message)

            })
            .finally(() => {
                this.setState({ isLoading: false });
            })
    }

    handlerChange(e) {

        const { name, value } = e.target;

        this.setState({
            formControl: {
                ...this.state.formControl,
                [name]: value
            }
        }, this.handlerValidation);


    }


    handlerValidation = () => {
        let errors = {};


        if (!this.regexUser.test(this.state.formControl.user.trim())) {
            errors.user = " Requerido ";
        }

        if (!this.regexPass.test(this.state.formControl.pass.trim())) {
            errors.pass = " Requerido ";
        }

        let block = Object.keys(errors).length > 0;

        this.setState({ buttonBlock: block, formErrors: errors });
    }

    handlerBlur(e) {
        this.handlerChange(e);
    }

    

    showAlert = (variant, text) => {
        this.setState({
            errorVariant: variant,
            alertMessage: text,
            alertOpen: true
        })

        setTimeout(() => {
            this.setState({
                alertOpen: false,
                alertMessage: "success"
            })
        }, 2000)
    }


    render() {

        return (

            <div className="d-flex h-100 align-items-center justify-content-center">

                <div className="d-flex justify-content-center flex-column align-items-center" >


                    <Alert className="w-100" variant={this.state.errorVariant} show={this.state.alertOpen}>{this.state.alertMessage && this.state.alertMessage}</Alert>

                    <div style={{ fontSize: '25px' }}><span className="pro">
                        <strong>Gas</strong></span><span className="admin">App</span> <BsDropletFill className="text-secondary" /></div>
                    <div>

                        <Form onSubmit={this.handlerSubmit} className="form-style" >

                            <fieldset disabled={this.state.isLoading}>

                                <Form.Group>
                                    <Form.Control name="user" type="text" placeholder="Usuario" value={this.state.formControl.user}
                                        onChange={this.handlerChange} onBlur={this.handlerBlur} required />

                                    {this.state.formErrors.user && <p className="input-message-danger">{this.state.formErrors.user}</p>}
                                </Form.Group>

                                <Form.Group>

                                    <Form.Control name="pass" type="password" placeholder="Contraseña" value={this.state.formControl.pass}
                                        onChange={this.handlerChange} onBlur={this.handlerBlur} required />

                                    {this.state.formErrors.pass && <p className="input-message-danger">{this.state.formErrors.pass}</p>}
                                </Form.Group>
                                <Form.Group>
                                    <Button type="submit" className="primary w-100 p-2" disabled={this.state.buttonBlock} > Acceso { this.state.isLoading && <Loading/>  }</Button>
                                </Form.Group>



                            </fieldset>

                        </Form>

                    </div>


                </div>

            </div>

        );
    }
}

Login.contextType = SessionContext

export default Login;