


export function buttonTagAction(action){
    const a = {
        create : "Crear",
        edit: "Actulizar",
        delete: "Eliminar"
    }
    return a[action] || "Crear";
}