import { Switch, Route, Redirect} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import NotFound from "./NotFound";
import './App.css';

import NavMenu from "./Components/NavMenu";
import Login from "./Components/Login";
import { SessionConsumer, SessionProvider } from "./context/sessionContext";

import Admin from "./Components/admin/Admin";
import Transportation from "./Components/transportation/Transportation";

import SideBar from "./Components/SideBar";


import ClientsPage from "./pages/ClientsPage";
import UnitiesPage from "./pages/UnitiesPage";
import PrivateRoute from "./Routes/PrivateRoute";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'




function App() {
    return (
        <div className="App">
            <div style={{ width: '100%' }}>

                <SessionProvider>

                    <SessionConsumer>
                        {
                            value => value.login ?
                                <>
                                    <NavMenu />

                                    <SideBar />
                                </>
                                : ""
                        }
                    </SessionConsumer>

                    <div className="container p-4 h-100 border-box">
                        <Switch>
                            <Route exact path="/login" component={Login} />
                            <PrivateRoute>
                                <Route exact path="/" render={()=> <Redirect to="/transportation/active" />}  /> 
                               
                                <Route path="/transportation" component={Transportation} />
                                <Route exact path="/clientspage" component={ClientsPage} />
                                <Route exact path="/admin" component={Admin} />
                                <Route exact path="/unitiespage" component={UnitiesPage} />

                            </PrivateRoute>

                            <Route path='*' exact component={NotFound} />
                        </Switch>
                    </div>

                </SessionProvider>

            </div>

            <ToastContainer />
        </div>

    );
}

export default App;
