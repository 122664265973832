import React, { Component } from 'react';

import { httpHelper } from "../../Services/HttpHelper";
import SessionContext from "../../context/sessionContext";
import { MdAddAPhoto } from "react-icons/md";
import { Button, Form } from "react-bootstrap";
import DataTable from "../DataTable";

import { Modal } from "react-bootstrap"
import { getPermissions } from '../../Services/getPermissions';
import { OperatorList } from '../operator/OperatorList';
import { Loading } from '../Loading';

import Avatar from '../Avatar';
import Clients from '../Clients';
import DialogDetail from '../DialogDetail';
import Transport from '../trasport/TransportComponent';
import { buttonTagAction } from '../../utils/ButtonTagAction';
import { TIER4 } from '../../utils/Utils';
import { GiCancel } from 'react-icons/gi';
import { FaUserPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';
import AccessDeny from '../AccessDeny';



class Admin extends Component {

    constructor(props) {
        super(props);

        this.userForm = { name: "", last_name: "", email: "", user: "", id_role: 0, action: "create", pass: "", id_client: 0, id_station: 0 };
        this.operatorForm = { name: "", rfc: "", licencia: "", action: "create", id_transport: 0 }

        this.state = {
            access: false,
            userForm: this.userForm,
            modal: false,
            permission: 0,
            modalOperator: false,
            operatorForm: this.operatorForm,
            userFormErrors: {},
            operatorErrors: {},
            activeOperators:[],
            loader: false,
            dialogDetail: { show: false, data: {} },
            displayStations: [],
            stationsForm: []
        }

        this.handlerSelection = this.handlerSelection.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.regexName = /^[A-Za-zÁ-ü]([A-Za-z0-9Á-ü-\s.]){4,50}$/
        this.regexEmail = /^([\w.\-_]+)?\w+@[\w-_]+(.\w+){1,}$/
        this.regexPassword = /^([A-Za-z0-9@$!%*?&#]){7,256}$/
        this.regexDigit = /^[1-9][0-9]{0,10}$/
        this.regexMayor = /^[1-9][0-9]*$/
        this.regexUser = /^[A-Za-z]([A-Za-z0-9]+){4,18}$/;
        this.regexRFC = /^[A-Za-z]([A-Za-z0-9]){9,12}$/
        this.regexLicense = /^[A-Za-z][A-Za-z0-9]{5,11}$/


    }


    userAction = (id, action, item) => {
        if (action === 'create') {
            this.setState({ modal: true })

        }
        if (action === 'edit') {
            this.setState({ modal: true, userForm: item })
        }

        if (action === 'delete') {
            this.setState({ modal: true, userForm: item })
        }

        if (action === 'detail') { this.openDialogDetail(item) }
    }

    performClickUser = () => {

        const action = this.state.userForm.action
        if (action === "create") {
            this.createUser()
        }

        if (action === "edit") {
            this.editUser()
        }

        if (action === 'delete') {
            this.removeUser()
        }
    }

    performClickOperator = () => {
        const action = this.state.operatorForm.action
        if (action === 'create') { this.createOperator() }
        if (action === 'edit') { this.updateOperator() }
        if (action === 'delete') { this.deleteOperator() }
    }


    createUser = () => {

        if (Object.keys(this.state.userFormErrors).length > 0) {
            toast("formulario no válido", { position: "top-right", type: "error" })
            return
        }
        this.setState({ loader: true })

        const url = `${process.env.REACT_APP_HOST}auth/register`
        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: JSON.stringify(this.state.userForm)
        }
        httpHelper().post(url, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message)

                this.closeModal()
                this.getUserData()
                this.setState({ loader: false })
                toast('creado', { position: "top-right", type: "success" })

            })
            .catch(err => {
                toast(err.message, { position: "top-right", type: "error" })
                this.closeModal()
                this.setState({ loader: false })
            })
    }

    editUser = () => {
        const url = `${process.env.REACT_APP_HOST}user`
        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: JSON.stringify(this.state.userForm)
        }
        httpHelper().put(url, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message)
                toast("Actualizado", { position: "top-right", type: "success" })
                this.getUserData()
                this.closeModal()
            })
            .catch(erro => {
                toast(erro.message, { position: "top-right", type: "error" })
                this.closeModal()
            })
    }

    removeUser = () => {
        const url = `${process.env.REACT_APP_HOST}auth/user`
        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: JSON.stringify(this.state.userForm)
        }
        httpHelper().del(url, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message)
                toast("Eliminado", { position: "top-right", type: "success" })
                this.closeModal()
                this.getUserData()
                this.setState({ loader: false })

            })
            .catch(err => {
                toast(err.message, { position: "top-right", type: "error" })
                this.closeModal()
                this.setState({ loader: false })
            })
    }

    operatorAction = (id, action, item) => {
        if (action === 'create') {
            this.setState({ modalOperator: true })
        }
        if (action === 'edit') {
            this.setState({ modalOperator: true, operatorForm: item })
        }

        if (action === 'delete') {
            this.setState({ modalOperator: true, operatorForm: item })
        }

        if (action === 'detail') { this.openDialogDetail(item) }

    }


    componentDidMount() {
        this.getUserData()

    }

    getUserData = () => {
        const token = this.context.getToken()
        let options = { headers: { authorization: token} };

        this.setState({ loader: true })
        httpHelper().get(`${process.env.REACT_APP_HOST}admin`, options)
            .then(data => {
                if (!data.response) throw new Error("error")

                this.setState({
                    users: data.users,
                    roles: data.roles,
                    operators: data.operators,
                    clients: data.clients,
                    transport: data.transport,
                    stations: data.stations
                })
            })
            .catch(e => toast.error(e.message, {position:"top-right"}))
            .finally(() => this.setState({ loader: false }))
    }

    handlerSelection(e) {

        const options = {
            headers: { authorization: process.env.REACT_APP_API_KEY }
        }
        httpHelper().get(`${process.env.REACT_APP_HOST}admin/menus?role=${e.target.value}`, options)
            .then(data => {
                this.setState({
                    menus: data
                })
            })
    }

    validationUser = () => {
        const errors = {}

        if (!this.regexName.test(this.state.userForm.name)) errors.name = "Requerido"
        if (!this.regexName.test(this.state.userForm.last_name)) errors.last_name = "Requerido"
        if (!this.regexEmail.test(this.state.userForm.email)) errors.email = "Requerido"
        if (!this.regexUser.test(this.state.userForm.user)) errors.user = "Requerido"
        if (!this.regexPassword.test(this.state.userForm.pass)) errors.pass = "Requerido"
        if (!this.regexDigit.test(this.state.userForm.id_role)) errors.id_role = "Requerido"

        if (parseInt(this.state.userForm.id_role) === 3)
            if (!this.regexDigit.test(this.state.userForm.id_station)) errors.id_station = "Requerido";

        if (parseInt(this.state.userForm.id_role) === 4) {
            if (!this.regexRFC.test(this.state.userForm.rfc)) errors.rfc = "Rfc no valido"
            if (!this.regexLicense.test(this.state.userForm.licencia)) errors.licencia = "Licencia no valida"
            if (!this.regexMayor.test(this.state.userForm.id_transport)) errors.id_transport = "Selecciona transportista"
        }

        this.setState({ userFormErrors: errors })
    }

    handleChange(e) {

        const target = e.target
        const { name, value } = target

        this.setState({
            userForm: {
                ...this.state.userForm,
                [name]: value
            }
        }, this.validationUser)

    }


    clickHandler = (e) => {
        this.setState({ modal: true })
    }

    closeModal = () => {
        this.setState({ modal: false, userForm: this.userForm })
    }

    closeModalOperator = () => {
        this.setState({ modalOperator: false, operatorForm: this.operatorForm })
    }


    imgStyle = (value) => {
        return <Avatar value={value} />
    }

    openDialogDetail = (data) => {
        this.setState({ dialogDetail: { show: true, data: data } })
    }

    closeDialogDetail = () => {
        this.setState({ dialogDetail: { show: false, data: {} } })
    }

    reload = () => { this.getUserData() }

    onClientSelect = (e) => {
        const { value, name } = e.target
        const id = parseInt(value)
        const client = this.state.clients?.filter(val => val.id === id)

        this.setState({
            displayStations: value !== 0,
            stationsForm: client[0]?.stations,
            userForm: {
                ...this.state.userForm,
                [name]: id
            }
        })
    }

    openModalOperator = (data) => {
    
        this.setState({modalOperator:true, activeOperators: data.operators})
    }


    /**
     * @property {{id_role:int, name: string}} item
     * @returns {JSX.Element}
     */

    render() {

        const columns = [
            { field: "img", title: "", style: this.imgStyle },
            { field: "name", title: "Nombre" },
            { field: "role", title: "Rol" }

        ]
        const columnsOperators = [
            { field: "img", title: "", style: this.imgStyle },
            { field: "name", title: "Nombre" },
            { field: "created_at", title: "Creado" },
        ]

        const per = getPermissions(this.context.user.access, "admin")

        return (
            <div>

                {per.access ?

                    <>
                        <h3> Admin Panel  </h3>

                        <div className="dashboard-grid">

                            <div className="card-content">
                                <h2 className="d-flex justify-content-between"> Usuarios {per.permission >= TIER4 ? <Button className="m-0" size="sm" onClick={this.clickHandler} >Nuevo <FaUserPlus /> </Button> : ""} </h2>

                                {
                                    this.state.loader ? <Loading /> :

                                        <>

                                            {this.state.users && <DataTable data={this.state.users}
                                                columns={columns}
                                                permission={per.permission}
                                                action={this.userAction} />}
                                        </>
                                }


                            </div>



                            <Clients
                                data={this.state.clients}
                                permission={per.permission}
                                reload={this.reload}
                            />


                            <Transport data={this.state.transport && this.state.transport}
                                permission={per.permission}
                                reload={this.reload}
                                onOperator={this.openModalOperator}
                            />



                        </div>


                        <Modal show={this.state.modal} onShow={this.validationUser} centered="true">

                            <Modal.Body>

                                <div className="d-flex flex-row-reverse">

                                    {
                                        this.state.userForm.user_id && <MdAddAPhoto className="pointer h3" />
                                    }
                                </div>

                                {
                                    this.state.userForm.action && this.state.userForm.action !== 'delete' ?
                                        <Form className="form-style">

                                            <fieldset disabled={this.state.loader}>

                                                <Form.Group>
                                                    <Form.Label>Nombre</Form.Label>
                                                    <Form.Control placeholder="Nombre" name="name" value={this.state.userForm.name} onChange={this.handleChange}
                                                        onBlur={this.handleChange}
                                                    />
                                                    {this.state.userFormErrors.name && <p className="input-message-danger">{this.state.userFormErrors.name}</p>}
                                                </Form.Group>

                                                <Form.Group>
                                                    <Form.Label>Apellido</Form.Label>
                                                    <Form.Control placeholder="Apellido" name="last_name" value={this.state.userForm.last_name} onChange={this.handleChange}
                                                        onBlur={this.handleChange} />
                                                    {this.state.userFormErrors.last_name && <p className="input-message-danger">{this.state.userFormErrors.last_name}</p>}
                                                </Form.Group>


                                                <Form.Group>
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control placeholder="email" name="email" value={this.state.userForm.email} onChange={this.handleChange}
                                                        onBlur={this.handleChange} />
                                                    {this.state.userFormErrors.email && <p className="input-message-danger">{this.state.userFormErrors.email}</p>}
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>usuario</Form.Label>
                                                    <Form.Control placeholder="user" name="user" value={this.state.userForm.user} onChange={this.handleChange}
                                                        onBlur={this.handleChange} />
                                                    {this.state.userFormErrors.user && <p className="input-message-danger">{this.state.userFormErrors.user}</p>}
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control placeholder="contraseña" name="pass" type="password" value={this.state.userForm.pass} onChange={this.handleChange}
                                                        onBlur={this.handleChange} />
                                                    {this.state.userFormErrors.pass && <p className="input-message-danger">{this.state.userFormErrors.pass}</p>}
                                                </Form.Group>

                                                <Form.Group>
                                                    <Form.FloatingLabel>Rol</Form.FloatingLabel>
                                                    <Form.Select value={this.state.userForm.id_role} name="id_role" onChange={this.handleChange} onBlur={this.handleChange}>
                                                        <option value="0">Selecciona Rol</option>

                                                        {this.state.roles && this.state.roles.map(item =>
                                                            <option key={item.id_role} value={item.id_role} > {item.name}</option>

                                                        )}
                                                    </Form.Select>
                                                    {this.state.userFormErrors.id_role && <p className="input-message-danger">{this.state.userFormErrors.id_role}</p>}
                                                </Form.Group>

                                                {parseInt(this.state.userForm.id_role) === 3
                                                    && <>

                                                        <Form.Group>
                                                            <Form.FloatingLabel>Cliente</Form.FloatingLabel>
                                                            <Form.Select name="id_client" onChange={this.onClientSelect} value={this.state.userForm.id_client && this.state.userForm.id_client}>
                                                                <option value={0} > Selecciona Cliente </option>
                                                                {
                                                                    this.state.clients?.map(item => (
                                                                        <option value={item.id}> {item.name} </option>
                                                                    ))
                                                                }
                                                            </Form.Select>
                                                        </Form.Group>

                                                        <Form.Group>
                                                            <Form.FloatingLabel>Estación</Form.FloatingLabel>
                                                            <Form.Select onChange={this.handleChange} name="id_station" value={this.state.userForm.id_station && this.state.userForm.id_station}>
                                                                <option value={0}>Selecciona Estación</option>
                                                                {
                                                                    this.state.stationsForm?.map(item => (
                                                                        <option value={item.id}> {item.code} - {item.address} </option>
                                                                    ))
                                                                }
                                                            </Form.Select>

                                                            {this.state.userFormErrors.id_station && <p className="input-message-danger">{this.state.userFormErrors.id_station}</p>}
                                                        </Form.Group>
                                                    </>


                                                }

                                                {

                                                    parseInt(this.state.userForm.id_role) === 4 && <>
                                                        <Form.Group>
                                                            <Form.Label>Rfc</Form.Label>
                                                            <Form.Control name="rfc" value={this.state.userForm.rfc} placeholder="Escribe Rfc"
                                                                onChange={this.handleChange} onBlur={this.handleChange} />
                                                            {this.state.userFormErrors.rfc && <p className="input-message-danger">{this.state.userFormErrors.rfc}</p>}
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Label>Licencia</Form.Label>
                                                            <Form.Control name="licencia" value={this.state.userForm.licencia} placeholder="Escribe Licencia"
                                                                onChange={this.handleChange} onBlur={this.handleChange} />
                                                            {this.state.userFormErrors.licencia && <p className="input-message-danger">{this.state.userFormErrors.licencia}</p>}
                                                        </Form.Group>

                                                        <Form.Group>
                                                            <Form.Label>Transportista</Form.Label>
                                                            <Form.Select name="id_transport" value={this.state.userForm.id_transport} onChange={this.handleChange}
                                                                onBlur={this.handleChange} >
                                                                <option value={0}>Selecciona Transportista</option>
                                                                {
                                                                    this.state.transport?.map(item => (
                                                                        <option value={item.id}>{item.name}</option>
                                                                    ))
                                                                }

                                                            </Form.Select>
                                                            {this.state.userFormErrors.id_transport && <p className="input-message-danger">{this.state.userFormErrors.id_transport}</p>}
                                                        </Form.Group>
                                                    </>


                                                }


                                            </fieldset>

                                        </Form> :

                                        <div>
                                            ¿ Eliminar al Usuario {this.state.userForm.name && this.state.userForm.name}?
                                        </div>

                                }

                            </Modal.Body>



                            <Modal.Footer>
                                <Button variant="danger" onClick={this.closeModal} disabled={this.state.loader}><GiCancel /> Cancelar</Button>
                                <Button className="btn-primary w-50" onClick={this.performClickUser} disabled={this.state.loader} >{this.state.loader && <Loading />} {buttonTagAction(this.state.userForm.action)}</Button>

                            </Modal.Footer>
                        </Modal>


                        <Modal show={this.state.modalOperator} centered="true" onHide={()=>this.setState({modalOperator:false})}>


                            <Modal.Body>

                                <OperatorList data={this.state.activeOperators}
                                    
                                    columns={columnsOperators}
                                    buttonNew={this.operatorButtonNew} />

                            </Modal.Body>

                        </Modal>

                        <DialogDetail show={this.state.dialogDetail.show} onHide={this.closeDialogDetail} data={this.state.dialogDetail.data} />

                    </>
                    : <AccessDeny />
                }



            </div >
        );
    }
}

Admin.contextType = SessionContext;
export default Admin;
