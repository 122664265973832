import React, { useContext, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import { FaTruckMoving } from 'react-icons/fa';
import { GoArrowRight } from 'react-icons/go';
import { httpHelper } from '../../Services/HttpHelper';
import img from "../../logo-user.png";
import { numberFormat, statusR, TIER2 } from '../../utils/Utils';
import SessionContext from '../../context/sessionContext';
import { toast } from 'react-toastify';
import { Loading } from '../Loading';
import { BsFillImageFill } from 'react-icons/bs';




const ModalTransportation = (props) => {

    const context = useContext(SessionContext);
    const [loader, setLoader] = useState(false);

    //eslint-disable-next-line
    const getImage = (url) => {
        return url === null ? img : url;
    }

    const confirm = () => {
        if (window.confirm("Finalizar Entrega")) doneDelivery();
    }

    const doneDelivery = () => {
        const id = props.data.id || 0;
        const token = context.getToken()
        const opt = {
            headers: { authorization: token },
            body: JSON.stringify({ id: id })
        }
        setLoader(true)
        httpHelper().put(`${process.env.REACT_APP_HOST}delivery/done`, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message);


                toast("Finalizada!!!", { position: "top-right", type: "success" })

                props.reload && props.reload()

            })
            .catch(e => toast(e.message, { position: "top-right", type: "error" }))
            .finally(() => setLoader(false))
    }

    const clickUploadEvidence = () => {
        props.onUpload && props.onUpload(props.data?.id)
    }


    const sta = props.data?.status || 0;

    return (

        <Modal show={props.show} centered={true} size="lg" onHide={() => props.setShowmodal(false)} >

            <Modal.Header closeButton={() => props.setShowmodal(false)} >

                <div>
                    {props.permission >= TIER2 && <>
                        {sta === statusR.DELIVERED ? <Button onClick={confirm} disabled={loader}> {loader && <Loading />} Finalizar</Button> : ""}

                        {(sta !== statusR.CANCELLED && sta < statusR.DELIVERED) && <BsFillImageFill onClick={clickUploadEvidence} className="pointer h4" />}
                    </>
                    }

                    {
                        props.profile&& (sta !== statusR.CANCELLED && sta < statusR.DELIVERED  && props.profile === 4) && <BsFillImageFill onClick={clickUploadEvidence} className="pointer h4" />
                    }

                </div>

            </Modal.Header>
            <Modal.Body>

                {props.data &&


                    <>
                        <div className="d-flex flex-column">

                            <div>

                                <div>Carga {props.data.load_date}</div>
                                <div>Descarga {props.data.download_date}</div>

                                <div>{props.data.product} {numberFormat(props.data.quantity || 0)}L</div>
                                <div></div>


                            </div>


                            <div className="d-flex justify-content-between">

                                <div>
                                    <h3>{props.data.id_extern}</h3>
                                </div>

                                <div className="d-flex justify-content-center align-items-center">

                                    <div className="d-flex flex-row align-items-center justify-content-between">

                                        <div> {props.data?.operator}</div>

                                    </div>
                                    <h3 className="me-3">{props.data.origin}</h3>
                                    <h3 className="me-3"><FaTruckMoving /><GoArrowRight /> {props.data?.code}</h3>
                                    <h3>{props.data.destiny}</h3>
                                    <h6>{props.data.address}</h6>
                                </div>
                            </div>


                            {props.data?.evidence &&
                                <div>

                                    <div className="card-content">
                                        <img style={{ maxWidth: '100%', maxHeight: '500px' }} src={props.data.evidence[0].url} alt="evidencess" />
                                    </div>

                                    <div >
                                        <ul>
                                            <li>Cantidad {numberFormat(props.data.evidence[0].quantity)}L</li>
                                            <li>{props.data.evidence[0].comment}</li>
                                            <li>{props.data.evidence[0].created_at}</li>
                                        </ul>

                                    </div>

                                </div>

                            }



                        </div>

                    </>


                }


            </Modal.Body>

        </Modal>
    );
}

export default ModalTransportation;