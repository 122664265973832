import { Badge } from "react-bootstrap";
import { FaTruckLoading } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";


const shortName = (name) =>{
    return name.split(' ')[0] || "";
}

const regexImage = /(\.png|\.jpg|\.webp)$/;
const regexDigit = /^[1-9][0-9]{0,10}$/
const regexComment = /^[A-Za-zÁ-ü]([A-Za-z0-9Á-ü-\s.,#-_]){4,255}$/

const TIER1 = 1;
const TIER2 = 2;
const TIER3 = 3;
const TIER4 = 4;

const statusR = {
    CANCELLED:0,
    CREATED:1,
    PROCCESS: 2,
    DELIVERED:2,
    CLOSED: 3

}
const statusDelivery = (per) => {
    const e = {
        0: <Badge bg="secondary"> Cancelado <GiCancel/></Badge>,
        1: <Badge bg="primary"> Activo</Badge>,
        2:  <Badge bg="success"> Descargado <FaTruckLoading className="h6"/></Badge> ,
        3: <Badge bg="secondary"> Finalizado </Badge> 
    }
    return e[per];
}


const styleActive = (value) => {

    const status = {
        0:<Badge pill bg="danger" style={{width:'14px', display:'block'}}></Badge>,
        1:<Badge pill bg="success" style={{width:'14px', display:'block'}}></Badge>
    }

    return status[value.status] || ""
}

const numberFormat = (number) => {
    return number.toLocaleString('es-Mx')
}


export {shortName, TIER1, TIER2, TIER3, TIER4, styleActive, numberFormat, statusR, regexImage, regexDigit, regexComment, statusDelivery };
