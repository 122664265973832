import { Button, Form, Modal} from "react-bootstrap";
import { useEffect, useState } from "react";
import DataTable from "../DataTable"
import { httpHelper } from "../../Services/HttpHelper";
import { buttonTagAction } from "../../utils/ButtonTagAction";
import { styleActive } from "../../utils/Utils";
import { toast } from "react-toastify";



const UnitiesList = (props) => {

    const regex = /^[A-Za-z]([A-Za-z-0-9]){3,15}$/
    const regexSerie = /^[A-Za-z1-9]([A-Za-z-0-9]){3,50}$/
    const regexDigit = /^[1-9][0-9]{0,10}$/
    const regexPlates = /^[A-Za-z1-9]([A-Za-z-0-9]){3,50}$/
    const regexName = /^[A-Za-zÁ-ü]([A-Za-z0-9Á-ü-\s.]){4,50}$/

    const columns = [
        { field: "status", title: "", style: styleActive },
        { field: "economico", title: "Econ" },
        { field: "brand", title: "Marca" },
        { field: "plates", title: "Placa" },
    ]
    const formDefault = { economico: "", brand: "", model: "", serie: "", plates: "", wheels: "", action: "create" }

    const [form, setForm] = useState(formDefault)
    const [modal, setModal] = useState(false)
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState({})

    const clickNew = () => { setModal(true) }

    const closeModal = () => {
        setModal(false)
        setForm(formDefault)
    }


    useEffect(() => {
        validate()
        // eslint-disable-next-line
    }, [form])

    const validate = () => {
        const errors = {}
        if (!regex.test(form.economico)) errors.economico = "Requerido"
        if (!regexName.test(form.brand)) errors.brand = "Requerido"
        if (!regexName.test(form.model)) errors.model = "Requerido"
        if (!regexSerie.test(form.serie)) errors.serie = "Requerido"
        if (!regexPlates.test(form.plates)) errors.plates = "Requerido"
        if (!regexDigit.test(form.wheels)) errors.wheels = "Requerido"

        setErrors(errors)
    }

   

    const handlerChange = (e) => {

        const { name, value } = e.target

        setForm({
            ...form,
            [name]: value
        })
    }

    const performClick = () => {

        const action = form.action
        if (action === 'create') newUnity();
        if (action === 'edit') update();
        if (action === 'delete') del();

    }

    const action = (id, action, item) => {
        setForm(item)
        setModal(true)
    }

    const newUnity = () => {
        if (Object.keys(errors).length > 0) {
            toast("Formulario no válido", {position:"top-right", type:"error"})
            return
        }
        setLoader(true)
        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: JSON.stringify(form)
        }
        httpHelper().post(`${process.env.REACT_APP_HOST}unity`, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message)
                toast("Creado", {position:"top-right", type:"success"})

                refresh()
              
            })
            .catch(e => { toast(e.message, {position:"top-right", type:"error"})
            })
            .finally(() => { 
                closeModal()
                setLoader(false)
            
            })



    }

    const refresh = () => {
        props.load && props.load()
    }

    const update = () => {
        if (Object.keys(errors).length > 0) {
            toast("Formulario no válido", {position:"top-right", type:"error"})
            return
        }
        setLoader(true)
        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: JSON.stringify(form)
        }
        httpHelper().put(`${process.env.REACT_APP_HOST}unity`, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message)
                toast("Actualizado", {position:"top-right", type:"success"})
                closeModal()
                refresh()
            })
            .catch(e => {
                toast(e.message, {position:"top-right", type:"error"})
                closeModal()
            })
            .finally(() => setLoader(false))


    }

    const del = () => {

        setLoader(true)
        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: JSON.stringify(form)
        }
        httpHelper().del(`${process.env.REACT_APP_HOST}unity`, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message)
                toast("Eliminado!!", {position:"top-right", type:"success"})

                refresh()
                closeModal()
            })
            .catch(e => {
                toast(e.message, {position:"top-right", type:"error"})
                closeModal()
            })
            .finally(() => setLoader(false))

    }


    return (

        <div className="card-content">
            <h2>Unidades {props.permission && props.permission >= 2 ? <Button className="m-0" size="sm" onClick={clickNew}>Nuevo+</Button> : ""}</h2>
            <DataTable columns={columns} data={props.data && props.data} permission={props.permission && props.permission} action={action} />

            <Modal show={modal} centered="true">

                <Modal.Body>

                    {
                        form.action !== 'delete' ?
                            <Form className="form-style">
                                <fieldset disabled={loader}>

                                    <Form.Group>
                                        <Form.Label>Economico</Form.Label>
                                        <Form.Control name="economico" className="text-uppercase" value={form.economico} onChange={handlerChange} onBlur={handlerChange} />
                                        {errors.economico && <p className="input-message-danger"> {errors.economico} </p>}
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Marca</Form.Label>
                                        <Form.Control name="brand" value={form.brand} onChange={handlerChange} onBlur={handlerChange} />
                                        {errors.brand && <p className="input-message-danger"> {errors.brand} </p>}
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Modelo</Form.Label>
                                        <Form.Control name="model" value={form.model} onChange={handlerChange} onBlur={handlerChange} />
                                        {errors.model && <p className="input-message-danger"> {errors.model} </p>}
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Serie</Form.Label>
                                        <Form.Control name="serie" value={form.serie} onChange={handlerChange} onBlur={handlerChange} />
                                        {errors.serie && <p className="input-message-danger"> {errors.serie} </p>}
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Placas</Form.Label>
                                        <Form.Control name="plates" value={form.plates} onChange={handlerChange} onBlur={handlerChange} />
                                        {errors.plates && <p className="input-message-danger"> {errors.plates} </p>}
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Llantas</Form.Label>
                                        <Form.Control name="wheels" type="number" value={form.wheels} onChange={handlerChange} onBlur={handlerChange} />
                                        {errors.wheels && <p className="input-message-danger"> {errors.wheels} </p>}
                                    </Form.Group>



                                </fieldset>
                            </Form> :
                            <div>
                                ¿Eliminar a {form.economico} ?
                            </div>
                    }

                </Modal.Body>


                <Modal.Footer>
                    <Button variant="danger" onClick={closeModal}>Cancelar</Button>
                    <Button onClick={performClick} >{buttonTagAction(form.action)}</Button>
                </Modal.Footer>
            </Modal>

        </div >

    )



}


export default UnitiesList;