import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FaGasPump } from "react-icons/fa";
import { Loading } from "../Components/Loading";
import { httpHelper } from "../Services/HttpHelper";
import GoogleMapReact from "google-map-react";


const ClientsPage = () => {


    const [clients, setClients] = useState([])
    const [loader, setLoader] = useState(false)
    const [stations, setStations] = useState([])


    useEffect(() => {
        getData()

    }, [])

    const getData = () => {
        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY }
        }
        setLoader(true)
        httpHelper().get(`${process.env.REACT_APP_HOST}client/all`, opt)
            .then((r) => {
                setClients(r.clients)
            })
            .catch((e) => { console.log(e.message) })
            .finally(() => setLoader(false))
    }

    const handlerChange = (e) => {
        const { value } = e.target
        loadStations(value)
    }

    const loadStations = (value) => {

        value = parseInt(value)

        const client = clients?.find(c => c.id === value)

        client === undefined ? setStations([]) : setStations(client.stations)
    }

    const defaultProps = {
        center: {
            lat: 20.672970256503937,
            lng: -103.31003371553102
        },
        zoom: 6
    };

    return (


        <div className="card-content">

            {
                loader ? <Loading /> :
                    <>


                        <Form className="form-style">
                            <Form.Select placeholder="Clients" className="w-50" onChange={handlerChange} >
                                <option key={0} value="0">Seleciona Cliente</option>

                                {
                                    clients?.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                }

                            </Form.Select>

                        </Form>

                        <div style={{ width: '100%', height: '500px' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_KEY }}
                                defaultCenter={defaultProps.center}
                                defaultZoom={defaultProps.zoom}>


                                {
                                    stations?.map(s => (
                                        <FaGasPump style={{fontSize:'2em'}} className="text-danger" lat={s.lat} lng={s.lon} text={s.code}/>
                                    ))
                                }


                            </GoogleMapReact>
                        </div>


                    </>
            }


        </div>

    )


}


export default ClientsPage;