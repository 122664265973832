export const httpHelper = () => {


    const customFetch = (endpoint, options) => {
        const defaultOptions = {
            accept: "application/json"
        }

        const controller = new AbortController()
        options.signal = controller.signal

        options.method = options.method || "GET"
        options.headers = options.headers ? {...defaultOptions, ...options.headers} : defaultOptions;

        options.body = options.body || false;
        if (!options.body) delete options.body;


        setTimeout(() => controller.abort(), 3000)

        return fetch(endpoint, options)
            .then(res => res.json())
            .catch((erro) => console.log(erro));


    };


    const get = (url, options = {}) => customFetch(url, options);

    const post = (url, options = {}) => {
        options.method = "POST";
        return customFetch(url, options);
    }

    const put = (url, options) => {
        options.method = "PUT";
        return customFetch(url, options)
    }

    const del = (url, options) => {
        options.method = "DELETE";
        return customFetch(url, options);

    }


    return {
        get,
        post,
        put,
        del
    }


}



