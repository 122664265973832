


const AccessDeny = () => {

    return (

        <div style={{height:'500px'}} className="d-flex justify-content-center align-items-center">

            <h2 className="text-secondary"> Acceso Denegado </h2>

        </div>

    )

}


export default AccessDeny;