import React, { Component } from "react";
import { Link } from "react-router-dom";
import { IoIosExit as ExitIcon } from "react-icons/io"

import './SideBar.css';
import SessionContext from "../context/sessionContext";
import { FaGasPump, FaTruck, FaUser } from "react-icons/fa";
import { BsDropletFill, BsFillBookmarkFill } from "react-icons/bs";
import { RiDashboardFill } from "react-icons/ri";


class SideBar extends Component {

    constructor(props) {
        super(props);


        this.state = { class: 'relative' }

        window.addEventListener('scroll', this.stickNavbar)

    }


    stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY
            windowHeight > 1 ? this.setState({ class: 'fixed' }) : this.setState({ class: 'relative' })
        }
    }


    icon = (value) => {
        const ic = {
            transportation: <BsDropletFill />,
            clientspage: <FaGasPump />,
            admin: <RiDashboardFill />,
            operator: <FaUser />,
            unities: <FaTruck />,
            unitiespage: <FaTruck />,
            promotor: <FaUser />,
            product: <BsDropletFill />
        }
        return ic[value] || <BsFillBookmarkFill />
    }

    render() {

        return (


            <div className="side-content">

                <ul>

                    {this.context.user.access?.map(val => {
                        const endpoint = val.endpoint === 'transportation' ? 'transportation/active'  : val.endpoint;
                        return <li key={val.endpoint}><Link to={`/${endpoint}`} className="nav-link link-light " >{this.icon(val.endpoint)} <span>{val.name} </span> </Link></li>
                    }

                    )}

                </ul>

                <footer>

                    {
                        this.context.login ?
                            <>

                                <div className="d-flex align-items-center">

                                    {
                                        Object.keys(this.context.user).length > 0 ?
                                            <>
                                                <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" alt="user" />
                                                <div className="user-name" style={{ marginLeft: '15px' }}> {this.context.user.name}</div>
                                            </> : ""
                                    }

                                </div>

                                <div className="d-flex align-items-center pointer">
                                    <ExitIcon onClick={this.context.logout && this.context.logout} style={{ fontSize: '1.5rem' }} />
                                </div>

                            </>
                            :
                            ""
                    }


                </footer>

            </div>

        )
    }


}


export default SideBar;

SideBar.contextType = SessionContext