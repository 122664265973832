
import { AiFillFileText } from "react-icons/ai"
import { BsFileZipFill } from "react-icons/bs"
import { FaFileInvoiceDollar } from "react-icons/fa"
import { toast } from "react-toastify"
import { httpHelper } from "../Services/HttpHelper"


const File = ({ type, url, name, id }) => {

    const format = (type) => {
        const iconformat = {
            pdf: <FaFileInvoiceDollar />,
            xml: <AiFillFileText />,
            zip: <BsFileZipFill />,
            invoice: <FaFileInvoiceDollar className="text-success pointer" />
        }

        return iconformat[type]
    }

    const handlerClick = (e) => {
        e.stopPropagation()
        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY }
        }
        httpHelper().get(`${process.env.REACT_APP_HOST}files?id=${id}`, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message)

                const a = document.createElement('a')
                a.href = r.file
                a.download = r.name
                a.target = '_blank'
                a.click()
            })
            .catch(e => toast(e.message, {position:"top-right", type:"error"}))

    }



    return (
        <>
            <div onClick={handlerClick}>{format(type)}</div>


        </>

    )
}

export default File;