import React, { Component } from "react";

import DataTable from "./DataTable";
import { Alert, Badge, Button, Form, Modal } from "react-bootstrap";
import SessionContext from "../context/sessionContext";
import { httpHelper } from "../Services/HttpHelper";

import Stations from "./client/stations";


import Avatar from "./Avatar";
import { RiGasStationFill } from "react-icons/ri";
import { FaGasPump, FaUserPlus } from "react-icons/fa";
import DialogDetail from "./DialogDetail";
import { buttonTagAction } from "../utils/ButtonTagAction";

export default class Clients extends Component {

    constructor(props) {
        super(props);

        this.client = { name: "", rfc: "", tag: "" , action: "create"}

        this.state = {
            loader: false,
            access: false,
            order: "desc",
            modal: false,
            alert: false,
            message: {},
            modalData: this.client,
            modalDelete: false,
            modalErrors: {},
            permission: 0,
            modalStations: false,
            stationData: [],
            modalDetail: {show:false, data:{}}
        }

        this.regexName = /^[A-Za-zÁ-ü.]([A-Za-z0-9Á-ü-\s.]){4,150}$/
        this.regexRFC = /^[A-Za-z]([A-Za-z0-9]){9,12}$/
        this.regexTag = /^[A-Za-zÁ-ü]([A-Za-z0-9Á-ü-\s#.]){4,150}$/

    }


    componentDidMount() {

        //this.setState({clients:this.props.data})

    }

    static getDerivedStateFromProps(nextProps, prevProps) {
        if (prevProps.data !== nextProps.data) {
            return { clients: nextProps.data }
        }
    }


    handlerAction = (id, action, client) => {
        if (action === 'stations') {
            this.setState({ modalStations: true, stationData: client })
        }else if (action === 'detail'){
            this.setState({modalDetail:{show:true, data:client}})
        } else {

            this.setState({ modal: true, modalData: client, modalAction: action })
        }
    }


    closeModal = () => {
        this.setState({ modalData: this.client, modal: false })
    }

    modalHandleClick = (e) => {

        const action = this.state.modalAction

        if (action === 'edit') {
            this.clientUpdate(this.state.modalData)
        } else if (action === 'delete') {
            this.deleteClient(this.state.modalData)
        }
        else {
            this.createClient()
        }

    }

    deleteClient = (client) => {

        const options = {
            headers: { authorization: process.env.REACT_APP_API_KEY }
        }
        httpHelper().del(process.env.REACT_APP_HOST + "client/" + client.id, options)
            .then(data => {
                if (!data.response) throw new Error()

                this.closeModal();
                this.createAlert("success", "Baja!!")
                this.refreshData();

            })
            .catch(err => {
                this.closeModal()
                this.createAlert('danger', err.message)
            });

    }

    clientUpdate = (client) => {

        const options = {
            headers: {
                authorization: process.env.REACT_APP_API_KEY
            },
            body: JSON.stringify(client)
        }

        httpHelper().put(`${process.env.REACT_APP_HOST}client/${client.id}`, options)
            .then(data => {
                if (!data.response) throw new Error(data.message)
                this.closeModal();
                this.refreshData();

                this.createAlert("warning", "Actualizado!!")

            })
            .catch(err => {
                this.closeModal()
                this.createAlert("danger", err.message)
            })

    }

    handleChangeData = (e) => {

        const target = e.target
        const value = target.value
        const name = target.name

        this.setState({
            modalData: {
                ...this.state.modalData,
                [name]: value
            }
        }, this.validation)
    }

    getStations = () => {

        const options = {
            headers: { authorization: process.env.REACT_APP_API_KEY }
        }

        httpHelper().get(`${process.env.REACT_APP_HOST}station`, options)
            .then(data => {
                this.setState({ stations: data.data });
            })
            .catch(error => { })
            .finally(() => this.setState({ loader: false }))

    }

    createAlert = (variant, text) => {
        this.setState({ alert: true, message: { variant: variant, text: text } })
        setTimeout(() => this.setState({ alert: false, message: {} }), 2000);
    }

    refreshData = () => {
        this.props.reload()
    }

    createClient = () => {

        if (Object.keys(this.state.modalErrors).length > 0) {
            alert("corrige los errores en el formulario")
            return;
        }

        const form = new FormData()

        form.append("name", this.state.modalData.name)
        form.append("rfc", this.state.modalData.rfc)
        form.append("tag", this.state.modalData.tag)

        const options = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: form
        }

        this.setState({ loader: true })

        httpHelper().post(process.env.REACT_APP_HOST + "client", options)
            .then(data => {
                if (!data.response) throw new Error(data.message)

                this.closeModal()
                this.refreshData()

                this.setState({ loader: false })
                this.createAlert("success", "Cliente creado!!!")
            })
            .catch(error => {

                this.closeModal()
                this.setState({ loader: false })

                this.createAlert("danger", error.message)
            })
    }

    avatarStyle = (value) => {
        return <Avatar value={value} />
    }

    validation = () => {
        const errors = {}

        if (!this.regexName.test(this.state.modalData.name)) errors.name = "El campo no puede ser vacío"
        if (!this.regexRFC.test(this.state.modalData.rfc)) errors.rfc = "El campo no puede ser vacío"
        if (!this.regexTag.test(this.state.modalData.tag)) errors.tag = "El campo no puede ser vacío"
        this.setState({ modalErrors: errors })
    }

    stations = (value) => {

        const stations = value.stations?.length

        return stations > 0 ? <Badge pill bg="primary" className="action-button pointer"
            onClick={() => this.onClickStation(value)}> <RiGasStationFill /> {stations} </Badge>
            : <Badge pill bg="secondary" className="action-button pointer"
            onClick={() => this.onClickStation(value)}><RiGasStationFill/></Badge>
    }

    onClickStation = (value) => {
        this.setState({ modalStations: true, stationData: value })
    }

    render() {

        const columns = [
            { title: "", field: "img", style: this.avatarStyle },
            { title: "Nombre", field: "name" },
            { title: "Alias", field: "tag" },
            { title: "Creado", field: "created_at" },
            { title: <FaGasPump />, style: this.stations }
        ]


        return (
            <div className="card-content two-columns">

                <h2 className="d-flex justify-content-between">Clientes
                    {this.props.permission && this.props.permission >= 2 ? <Button variant="primary" className="m-0" size="sm"
                        onClick={() => this.setState({ modal: true, modalAction: 'Crear' })}>Nuevo <FaUserPlus/> </Button> : ""}</h2>

                <DataTable permission={this.props.permission && this.props.permission}
                    columns={columns}
                    data={this.state.clients && this.state.clients}
                    action={this.handlerAction}
                />

                <Modal show={this.state.modal} centered={true} onShow={() => this.validation()}>
                    <Alert show={this.state.alert}
                        variant={this.state.message.variant && this.state.message.variant}>
                        {this.state.message.text && this.state.message.text}
                    </Alert>

                    <Modal.Body>

                        {
                            this.state.modalAction && this.state.modalAction !== 'delete' ?
                                <Form className="form-style">

                                    <fildset disabled={this.state.loader}>

                                        <Form.Group>
                                            <Form.Label>Nombre</Form.Label>
                                            <input className="form-control" placeholder="escribe nombre"
                                                name="name"
                                                value={this.state.modalData && this.state.modalData.name}
                                                onChange={this.handleChangeData}
                                                onBlur={this.handleChangeData}
                                                autoFocus
                                            />

                                            {this.state.modalErrors.name && <p className="input-message-danger"> {this.state.modalErrors.name}</p>}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Rfc</Form.Label>
                                            <input className="form-control" placeholder=""
                                                name="rfc"
                                                value={this.state.modalData.rfc && this.state.modalData.rfc}
                                                onChange={this.handleChangeData}
                                                onBlur={this.handleChangeData}
                                            />
                                            {this.state.modalErrors.rfc && <p className="input-message-danger"> {this.state.modalErrors.rfc}</p>}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Alias</Form.Label>
                                            <input className="form-control" placeholder="escribe direccion"
                                                name="tag"
                                                value={this.state.modalData.tag && this.state.modalData.tag}
                                                onChange={this.handleChangeData}
                                                onBlur={this.handleChangeData}
                                            />
                                            {this.state.modalErrors.rfc && <p className="input-message-danger"> {this.state.modalErrors.tag}</p>}
                                        </Form.Group>
                                    </fildset>

                                </Form>
                                :
                                <div>
                                    ¿Estas seguro que quieres Eliminar al usuario {this.state.modalData && this.state.modalData.name}?
                                </div>
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-danger" onClick={this.closeModal}>cancelar</Button>
                        <Button className="btn-primary" onClick={this.modalHandleClick} >{this.state.modalData && buttonTagAction(this.state.modalData.action)}</Button>
                    </Modal.Footer>
                </Modal>

                <Modal size="lg" show={this.state.modalStations} centered="true">
                    <Modal.Body>

                        <Stations data={this.state.stationData}
                            permission={this.props.permission && this.props.permission}
                            clients={this.state.clients && this.state.clients}
                            reload={this.refreshData}
                        />
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={() => this.setState({ modalStations: false, stationData: [] })} >Cancelar</Button>
                    </Modal.Footer>
                </Modal>

                <DialogDetail show={this.state.modalDetail.show} data={this.state.modalDetail.data} 
                                onHide={() => this.setState({modalDetail:{show:false,data:{}}})}
                                />

            </div>

        )
    }


};


Clients.contextType = SessionContext;


