import React, {useContext, useEffect, useState} from 'react';
import TransportationList from "./TransportationList";
import {httpHelper} from "../../Services/HttpHelper";
import SessionContext from "../../context/sessionContext";
import {getPermissions} from "../../Services/getPermissions";
import {Badge, Button, Col, Form, Modal, Nav, Row} from "react-bootstrap";
import ModalTransportation from "./ModalTransportation";
import {BsDropletFill, BsDropletHalf, BsFillDropletFill, BsFillImageFill} from "react-icons/bs";
import {FaCloudUploadAlt, FaGasPump, FaTruckMoving, FaUser} from "react-icons/fa";
import {GoArrowRight} from "react-icons/go";
import {GiCancel, GiFuelTank} from "react-icons/gi";
import {buttonTagAction} from '../../utils/ButtonTagAction';
import {Link, Route, Switch, useRouteMatch} from 'react-router-dom';
import AccessDeny from '../AccessDeny';
import {regexDigit, regexImage, TIER2} from '../../utils/Utils'
import NotFound from '../../NotFound';
import {toast} from 'react-toastify';
import {Loading} from '../Loading';


const Transportation = () => {

    let { path, url } = useRouteMatch();

    const regexMayor = /^[1-9][0-9]*$/
    //const regexDate = /^([0-3][0-9])([/])([0-1][0-2])([/])([2-9][0-9][0-9][0-9])$/
    const regexDateI = /^([2-9][0-9][0-9][0-9])([-])([0-1][0-9])([-])([0-3][0-9])$/
    const formDefault = { load_date: "", download_date: "", id_station: 0, id_product: 0, id_unity: 0, id_operator: 0, quantity: "", tank: [], action: "create" }
    const defaultFilter = { year: 0, text: "", date: "" }
    const defaultPermission = { access: false, permission: 0 };
    const defaultEvidence = { show: false, data: { image: "", quantity: 0, comment: "" } }

    const [transportation, setTransportation] = useState([]);
    const [permission, setPermission] = useState(defaultPermission);
    const [showModal, setShowmodal] = useState(false);
    const [dataModal, setDatamodal] = useState({})
    const [loader, setLoader] = useState(false)
    const [filtered, setFiltered] = useState(defaultFilter)
    const context = useContext(SessionContext)
    const [modalNew, setModalNew] = useState({ show: false, data: formDefault })
    const [options, setOptions] = useState({ operators: [], stations: [], unities: [], products: [] })
    const [errors, setErrors] = useState({})
    const [evidenceErrors, setEvidenceErrors] = useState({})
    const [formEvidence, setFormEvidence] = useState(defaultEvidence)

    const [years, setYears] = useState([])


    useEffect(()=> {
        validateEvidence()
        // eslint-disable-next-line
    },[formEvidence])

    useEffect(()=>{

         // eslint-disable-next-line
        const interval =setInterval(() => {

            getData()
            
        }, 5 * 60 * 1000 );

        return () => clearInterval(interval);

    }, [])

    useEffect(() => {
        const endpoint = "transportation";

        if (context.user.access) {

            const per = getPermissions(context.user.access, endpoint);

            setPermission(per);
        }

    }, [context])

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [context.user.id_role, filtered])



    useEffect(() => {
        getYears()
    }, [transportation])

    // eslint-disable-next-line
    useEffect(() => validation(), [modalNew.data])

    const validation = () => {
        const errors = {}

        if (!regexDateI.test(modalNew.data?.load_date)) errors.load_date = "Formato dd/mm/aaaa";
        if (!regexDateI.test(modalNew.data?.download_date)) errors.download_date = "Formato dd/mm/aaaa";
        if (!regexMayor.test(modalNew.data?.id_station)) errors.id_station = "Selecciona una estación";
        if (!regexMayor.test(modalNew.data?.id_product)) errors.id_product = "Selecciona un producto";
        if (!regexMayor.test(modalNew.data?.id_operator)) errors.id_operator = "Selecciona un operador";
        if (!regexMayor.test(modalNew.data?.id_unity)) errors.id_unity = "Selecciona una unidad";
        if (!regexMayor.test(modalNew.data?.quantity)) errors.quantity = "ingresa cantidad";

        if (modalNew.data.tank?.length >= 1 && modalNew.data.tank?.length < 3) {
            let capacity = 0
            const tanks = getTanks(options.tanks, modalNew.data.tank)
            tanks.map(item => {
                capacity += item.capacity
                return 0;
            })

            if (capacity < modalNew.data.quantity) errors.tank = "Capacidad insuficiente";

        } else {
            errors.tank = "Selecciona un tanques necesarios";
        }

        setErrors(errors)
    }

    const getTanks = (tanks, find) => {
        return tanks.filter(i => find.includes(i.id))
    }

    const getData = () => {

        const user = context?.user
        const date = filtered.date
        if (!user.id_role) return;

        const url = `${process.env.REACT_APP_HOST}delivery`;
        const options = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: JSON.stringify({ token: user.token, id_station: user.id_station, id_role: user.id_role, date: date, user_id:user.user_id })
        }
        setLoader(true)
        httpHelper().post(url, options)
            .then(json => {
                if (json.data) {

                    setTransportation(json.data);
                    setOptions({
                        operators: json.operators,
                        stations: json.stations,
                        unities: json.unities,
                        products: json.products,
                        tanks: json.tanks
                    })

                }
            })
            .catch(erro => console.log(erro))
            .finally(() => setLoader(false))

    }

    const handlerClickCard = (data) => {
        setShowmodal(true)
        setDatamodal(data);
    }



    const formHandlerChange = (e) => {

        let { value, name } = e.target

        if (e.target.multiple) {
            let v = []
            const options = e.target.selectedOptions
            for (let i = 0; i < options.length; i++) {
                v.push(parseInt(options.item(i).value))
            }
            value = v;
        }

        setModalNew({
            data: { ...modalNew.data, [name]: value },
            show: modalNew.show
        })

    }


    const performClick = () => {
        const action = modalNew.data.action
        if (action === 'create') { create() }
        if (action === 'delete') { del() }
        if (action === 'edit') { update() }
    }

    const create = () => {
        if (Object.keys(errors).length > 0) {
            toast("formulario no válido", { position: "top-right", type: "error" })
            return
        }

        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: JSON.stringify(modalNew.data)
        }
        setLoader(true)
        httpHelper().post(`${process.env.REACT_APP_HOST}delivery/create`, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message)
                closeModal()
                toast("Creado", { position: "top-right", type: "success" })
                getData()
            })
            .catch(e => toast(e.message, { position: "top-right", type: "error" }))
            .finally(() => setLoader(false))

    }


    const update = () => {

        if (Object.keys(errors).length > 0) {
            toast("formulario no válido", { position: "top-right", type: "error" })
            return
        }

        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: JSON.stringify(modalNew.data)
        }
        setLoader(true)
        httpHelper().put(`${process.env.REACT_APP_HOST}delivery`, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message)
                closeModal()
                toast("Actualizado!!!", { position: "top-right", type: "success" })
                getData()
            })
            .catch(e => toast(e.message, { position: "top-right", type: "error" }))
            .finally(() => setLoader(false))

    }

    const del = () => {

        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: JSON.stringify(modalNew.data)
        }
        setLoader(true)
        httpHelper().del(`${process.env.REACT_APP_HOST}delivery`, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message)
                closeModal()
                toast("Eliminado!!", { position: "top-right", type: "success" })
                getData()
            })
            .catch(e => toast(e.message, { position: "top-right", type: "error" }))
            .finally(() => setLoader(false))

    }

    const handlerAction = (data, action) => {
        if (action === 'detail') {
            setShowmodal(true)
            setDatamodal(data);
        }
        if (action === 'edit') {
            setModalNew({ show: true, data: data })
        }

        if (action === 'delete') {
            setModalNew({ show: true, data: data })
        }


    }

    const clickNew = () => {
        setModalNew({ show: true, data: modalNew.data })
    }

    const closeModal = () => {
        setModalNew({ show: false, data: formDefault })
    }

    const activeCount = () => {
        return transportation.filter(i => i.status === 1 || i.status === 2);
    }
    const closedCount = () => {
        return transportation.filter(i => i.status === 3);
    }
    const cancelledCount = () => {
        return transportation.filter(i => i.status === 0);
    }

    const getYears = () => {
        const years = []
        const date = new Date()
        const year = date.getFullYear()

        for (let i = 0; i <= 2; i++) {
            const y = year - i;
            years.push(y)
        }

        setYears(years)

    }

    const reloadData = () => {
        getData()
        setShowmodal(false)
    }

    const onClickEvidence = (id) => {

        const d = {...formEvidence.data, id:id}
        setFormEvidence({show:true, data:d})

    }

    const sendEvidence = () => {

        if(Object.keys(evidenceErrors).length > 0){
            toast.error("Formulario no válido", {position:"top-right"})
            return 
        }
        
        const token = context.getToken()

        const formData = new FormData()
        formData.append("id_delivery", formEvidence.data.id)
        formData.append("quantity", formEvidence.data.quantity)
        formData.append("comment", formEvidence.data.comment)
        formData.append("image", formEvidence.data.image)


        const opt = {
            headers: { authorization: token },
            body: formData
        }
        setLoader(true)
        httpHelper().post(`${process.env.REACT_APP_HOST}delivery/evidence`, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message);

                toast("Evidencia Subida!!!", { position: "top-right", type: "success" })

                reloadData()

            })
            .catch(e => toast(e.message, { position: "top-right", type: "error" }))
            .finally(() => {
                setFormEvidence(defaultEvidence)
                setLoader(false)
            })
    }

    const onChangeEvidence = (e) => {

        const { name,value } = e.target;
        let val = value
        
        if(e.target.type === "file") {
            val = e.target.files[0]
        }
        setFormEvidence( {show:true, 
            data:{
                ...formEvidence.data,
                [name]:val
            }
        } )

    }

    const validateEvidence = () => {
        const errors = {}

        if(!regexImage.test(formEvidence.data?.image.name)) errors.image = "Solo PNG, JPG, WEBP son permitidos"
        if(!regexDigit.test(formEvidence.data?.quantity)) errors.quantity = "Requerido"
        
        setEvidenceErrors(errors)
    }


    return (
        <div>

            {permission.access ?

                <>


                    <div>

                        <h3 className="d-flex justify-content-between mb-4">Envíos {
                            permission.permission >= TIER2 &&
                            <Button size="sm" className="m-0" onClick={clickNew}><BsDropletFill />  Nuevo +</Button>
                        } </h3>

                        <Nav justify variant="tabs" defaultActiveKey={`active`}>
                            <Nav.Item>
                                <Nav.Link as={Link} to={`${url}/active`}>Activo <Badge pill bg="secondary" >{activeCount().length}</Badge></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to={`${url}/closed`}>Finalizado <Badge pill bg="secondary">{closedCount().length}</Badge></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to={`${url}/cancelled`}>Cancelado <Badge pill bg="secondary" >{cancelledCount().length}</Badge></Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Form className="d-flex justify-content-between">

                                    {/* <Form.Select name="year" onChange={(e) => setFiltered({ ...filtered, year: e.target.value })}>
                                        {
                                            years?.map(y => <option value={y}> {y}</option>)
                                        }
                                    </Form.Select> */}


                                    <Form.Control type="date" name="date" onChange={(e) => setFiltered({...filtered, date: e.target.value})} />

                                    <Form.Control type="text" name="search" className="me-2" aria-label="Search" placeholder="Buscar"
                                        onChange={(e) => setFiltered({ ...filtered, text: e.target.value })} />

                                </Form>
                            </Nav.Item>

                        </Nav>
                        <Switch>
                            <Route exact path={`${path}/active`}>
                                <TransportationList loader={loader} refresh={reloadData} handler={handlerClickCard}
                                    action={handlerAction}
                                    list={activeCount()} permission={permission.permission} filter={filtered.text} />
                            </Route>

                            <Route exact path={`${path}/closed`}>
                                <TransportationList loader={loader} refresh={reloadData} handler={handlerClickCard} action={handlerAction} list={closedCount()} permission={permission.permission} filter={filtered.text} />
                            </Route>
                            <Route exact path={`${path}/cancelled`}>
                                <TransportationList loader={loader} refresh={reloadData} handler={handlerClickCard} action={handlerAction} list={cancelledCount()} permission={permission.permission} filter={filtered.text} />
                            </Route>

                            <Route path="*" exact={true} component={NotFound} />

                        </Switch>


                        <ModalTransportation reload={reloadData} onUpload={onClickEvidence} show={showModal} data={dataModal} setShowmodal={setShowmodal} 
                            permission={permission.permission} profile={context.user.id_role&&context.user.id_role} />

                    </div>
                    <Modal show={modalNew.show} centered="true" onShow={validation} className="w50" >

                        <Modal.Header>
                            <p className="text-center"><BsDropletFill /> <GoArrowRight />  <FaTruckMoving /></p>
                        </Modal.Header>

                        <Modal.Body >

                            {
                                modalNew.data?.action !== 'delete' ?
                                    <Form className="form-style">

                                        <fieldset disabled={loader}>
                                            <Row className="p-0 mb-1">

                                                <Col className="p-1 mb-1">
                                                    <Form.Label>Carga</Form.Label>
                                                    <Form.Control name="load_date" type="date" onChange={formHandlerChange} value={modalNew.data?.load_date} />
                                                    {errors.load_date && <p className="input-message-danger">{errors.load_date}</p>}
                                                </Col>

                                                <Col className="p-1 mb-1">
                                                    <Form.Label>Descarga</Form.Label>
                                                    <Form.Control name="download_date" type="date" onChange={formHandlerChange} value={modalNew.data?.download_date} />
                                                    {errors.download_date && <p className="input-message-danger">{errors.download_date}</p>}
                                                </Col>

                                            </Row>


                                            <Form.Group>

                                                <Form.Label><FaGasPump /> Estacion</Form.Label>

                                                <Form.Select name="id_station" value={modalNew.data?.id_station} onChange={formHandlerChange} onSelect={formHandlerChange}>
                                                    <option value={0}>Seleciona </option>
                                                    {
                                                        options.stations?.map(item => (
                                                            <option value={item.id}>{item.address}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                                {errors.id_station && <p className="input-message-danger">{errors.id_station}</p>}
                                            </Form.Group>


                                            <Form.Group>
                                                <Form.Label><FaUser /> Operador</Form.Label>
                                                <Form.Select name="id_operator" value={modalNew.data?.id_operator} onChange={formHandlerChange} onBlur={formHandlerChange}>
                                                    <option value={0}>Seleciona </option>
                                                    {
                                                        options.operators?.map(item => (
                                                            <option value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                                {errors.id_operator && <p className="input-message-danger">{errors.id_operator}</p>}
                                            </Form.Group>


                                            <Row className="p-0">
                                                <Col className="p-1">
                                                    <Form.Label><BsFillDropletFill /> Producto</Form.Label>
                                                    <Form.Select name="id_product" value={modalNew.data?.id_product} onChange={formHandlerChange} onBlur={formHandlerChange}>
                                                        <option value={0}>Seleciona </option>
                                                        {
                                                            options.products?.map(item => (
                                                                <option value={item.id}>{item.name}</option>
                                                            ))
                                                        }
                                                    </Form.Select>
                                                    {errors.id_product && <p className="input-message-danger">{errors.id_product}</p>}
                                                </Col>
                                                <Col className="p-1">
                                                    <Form.Label><BsDropletHalf /> Cantidad</Form.Label>
                                                    <Form.Control name="quantity" value={modalNew.data?.quantity} placeholder="Lts" onChange={formHandlerChange} onBlur={formHandlerChange} />
                                                    {errors.quantity && <p className="input-message-danger">{errors.quantity}</p>}
                                                </Col>
                                            </Row>

                                            <Row className="p-0">
                                                <Col className="p-1">

                                                    <Form.Label> <FaTruckMoving /> Unidad</Form.Label>
                                                    <Form.Select name="id_unity" value={modalNew.data?.id_unity} onChange={formHandlerChange} onBlur={formHandlerChange}>
                                                        <option value={0}>Seleciona </option>
                                                        {
                                                            options.unities?.map(item => (
                                                                <option value={item.id}>{item.brand} - {item.plates}</option>
                                                            ))
                                                        }
                                                    </Form.Select>

                                                    {errors.id_unity && <p className="input-message-danger">{errors.id_unity}</p>}

                                                </Col>
                                                <Col className="p-1">


                                                    <Form.Label><GiFuelTank /> tanque</Form.Label>
                                                    <Form.Select name="tank" multiple onChange={formHandlerChange} value={modalNew.data?.tank}
                                                        onSelect={formHandlerChange} className="basic-multi-select"
                                                    >

                                                        {
                                                            options.tanks?.map(item => (
                                                                <option value={item.id}>{item.plates} {item.capacity}</option>
                                                            ))
                                                        }
                                                    </Form.Select>

                                                    {errors.tank && <p className="input-message-danger">{errors.tank}</p>}

                                                </Col>
                                            </Row>


                                        </fieldset>



                                    </Form>
                                    : <div>
                                        ¿Cancelar entrega ?
                                    </div>
                            }



                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="danger" onClick={closeModal} disabled={loader}><GiCancel /> Cancelar</Button>
                            <Button className="w-50" onClick={performClick} disabled={loader}>{loader&&<Loading/>} {buttonTagAction(modalNew.data.action)}</Button>
                        </Modal.Footer>



                    </Modal>


                    <Modal show={formEvidence.show} centered>

                        <Form className="form-style">

                            <fieldset disabled={loader}>

                                <Form.Group>
                                    <Form.Label><BsFillImageFill/> Image</Form.Label>
                                    <Form.Control name="image" type="file" onChange={onChangeEvidence}
                                        className={`${evidenceErrors.image && 'border-red'}`} accept="image/*"/>

                                    {evidenceErrors.image && <p className="input-message-danger" >{evidenceErrors.image}</p>}
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Cantidad</Form.Label>
                                    <Form.Control name="quantity" type="number" onChange={onChangeEvidence}
                                        className={`${evidenceErrors.quantity && 'border-red'}`} />
                                    {evidenceErrors.quantity && <p className="input-message-danger" >{evidenceErrors.quantity}</p>}
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Comentario</Form.Label>
                                    <Form.Control name="comment" onChange={onChangeEvidence}
                                        className={`${evidenceErrors.comment && 'border-red'}`} />
                                    {evidenceErrors.comment && <p className="input-message-danger" >{evidenceErrors.comment}</p>}
                                </Form.Group>

                            </fieldset>


                        </Form>

                        <Modal.Footer>

                            <Button variant="danger" disabled={loader} onClick={() => setFormEvidence(defaultEvidence) }> <GiCancel /> Cancelar </Button>
                            <Button onClick={sendEvidence} disabled={loader}> {loader && <Loading />} <FaCloudUploadAlt />   Subir</Button>


                        </Modal.Footer>

                    </Modal>




                </>

                : <AccessDeny />
            }




        </div>
    );
}



export default Transportation;