import React, {Component} from 'react';
import { Container, Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";

import './NavMenu.css';
import SessionContext from "../context/sessionContext";
import {BsDropletFill} from "react-icons/bs";
import {FiUser} from "react-icons/fi";


class NavMenu extends Component {


    constructor(props) {
        super(props);

        this.state = {
            menuData: {}

        }
    }

    componentDidMount(){
        if(this.context.user){
            this.setState({menuData:this.context.user})
        }

    }

    render() {
        return (
            <Navbar style={{backgroundColor: '#2e4470'}} expand="sm">
                <Container>

                    <Navbar.Brand>
                        <Link to="/" className="nav-link link-light">
                            <span className="pro">
                        <strong>Gas</strong></span><span className="admin">App</span><span className="pro"><strong>line</strong></span> <BsDropletFill/>
                        </Link>
                    </Navbar.Brand>
                

                    <div className="d-flex align-items-center">

                    {
                        Object.keys(this.context.user).length > 0 ?
                            <div className="text-white">{this.context.user.name}</div> :
                            <Link to="/login" className="nav-link link-light"><FiUser
                                style={{fontSize: '1.5rem'}}/></Link>
                        }
                                           
                    </div>

               
                </Container>
            </Navbar>
        );
    }
}


export default NavMenu;

NavMenu.contextType = SessionContext