
import {httpHelper} from "./HttpHelper";

export const LoginService = () => {


    const login = (url, options) => {
        return httpHelper().post(url, options);
    }


    return {
        login
    }
}

