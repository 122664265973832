import React from "react";



const NotFound = () => (
    <div style={{minHeight:"80vh"}} className="d-flex justify-content-center align-items-center" >
        <h1 style={{color:"#5e5e5e"}}>Not found</h1>
    </div>


);



export default NotFound;