import React, {Component} from 'react';

import {BiEdit} from "react-icons/bi";
import {AiFillDelete} from "react-icons/ai";

import './ButtonAction.css'
import PropTypes from "prop-types";
import { RiGasStationFill } from 'react-icons/ri';
import { BsFillEyeFill } from 'react-icons/bs';
import { statusR, TIER4 } from '../utils/Utils';

class ButtonsAction extends Component {

    clickEdit = (e) =>{
        e.stopPropagation()
        this.props.type&&this.props.type('edit')
    }

    clickDelete = (e) =>{
        e.stopPropagation()
        this.props.type&&this.props.type('delete')
    }

    clickPurchase = (e) => {
        e.stopPropagation()
        this.props.type&&this.props.type('purchase')
    }

    clickStation = (e) => {
        e.stopPropagation()
        this.props.type&&this.props.type('stations')
    }

    clickDetail = (e) => {
        e.stopPropagation()
        this.props.type&&this.props.type('detail')
    } 

    render() {

        const status = this.props.status || 0;

        return (
            <div className="d-flex justify-content-between action-icon" style={{maxWidth:'80px', minWidth:'70px'}}>

                {
                    this.props.permission >= TIER4  ?
                        <>
                            <BiEdit onClick={this.clickEdit} className="hover-green"/>

                            {status > statusR.CANCELLED && <AiFillDelete onClick={this.clickDelete} className="hover-red"/>}
                            
                        </>
                        : ""
                }

                {
                    this.props.stations&&
                        this.props.stations ? <RiGasStationFill onClick={this.clickStation} className="hover-blue" /> : ""
                }

                <BsFillEyeFill onClick={this.clickDetail} className="hover-blue" />

            </div>
        );
    }
}


ButtonsAction.propTypes = {
    permission: PropTypes.number.isRequired
}


export default ButtonsAction;