import React from "react";
import { Badge, Button, Form, Modal } from "react-bootstrap";

import { AiFillFileAdd } from "react-icons/ai";
import {  FaCloudUploadAlt, FaTruckMoving} from "react-icons/fa";
import { GiCancel } from "react-icons/gi";
import { GoArrowRight} from "react-icons/go";
import { ImFilePdf } from "react-icons/im";

import { httpHelper } from "../../Services/HttpHelper";
import { shortName, statusDelivery } from "../../utils/Utils";
import File from "../File";

import { numberFormat } from "../../utils/Utils";

import { Loading } from "../Loading";
import { BsDropletHalf } from "react-icons/bs";
import { toast } from "react-toastify";
import ButtonsActionCard from "../ButtonsActionCard";


const priceFormat = (number) => {

    let options = { style: 'currency', currency: 'MXN' }
    let format = new Intl.NumberFormat('es-MX', options);

    return format.format(number);

}


class CardTransportation extends React.Component {

    constructor(props) {
        super(props);
        this.defaultFiles = { pdf: "", xml: "" }

        this.state = {
            modal: false,
            dataFiles: this.defaultFiles,
            errors: {},
            loader: false
        }
        this.handlerClickCard = this.handlerClickCard.bind(this);

        this.regexPDF = /(\.pdf)$/i;
        this.regexXML = /(\.xml)$/i;


    }


    status = (per) => {
        const e = {
            0: { text: "Cancelado", color: "danger", className: "closed" },
            1: { text: "Activo", color: "primary", className: "" },
            2: { text: "Descargado", color: "warning", className: "" },
            3: { text: "Cerrado", color: "success", className: "closed" }
        }
        return e[per];
    }

    handlerClickCard(e) {
        this.props.data && this.props.handler(this.props.data);
    }

    handleClickAction = (type) => {

        this.props.action && this.props.action(this.props.data, type)
    }

    openModalFiles = (e) => {
        e.stopPropagation()
        this.setState({ modal: true })
    }
    closeModalFiles = () => {
        this.setState({ modal: false })
    }

    validation = () => {
        const errors = {}


        if (!this.regexPDF.test(this.state.dataFiles.pdf?.name)) errors.pdf = "Archivo Pdf requerido"
        if (!this.regexXML.test(this.state.dataFiles.xml?.name)) errors.xml = "Archivo XML requerido"

        this.setState({
            errors: errors
        })
    }


    onChangeFileHandler = (e) => {
        const { name } = e.target;
        const files = e.target.files

        this.setState({
            dataFiles: {
                ...this.state.dataFiles,
                [name]: files[0]
            }
        }, this.validation)

    }

    uploadFiles = () => {

        if (Object.keys(this.state.errors).length > 0) {
           
            toast("algunos archivos nos se han seleccionado", {position:"top-right", type:"error"})
            return;
        }

        const form = new FormData()
        form.append('xml', this.state.dataFiles.xml)
        form.append('pdf', this.state.dataFiles.pdf)
        form.append('id', this.props.data.id)
        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: form
        }

        this.setState({ loader: true })
        httpHelper().post(`${process.env.REACT_APP_HOST}/files/file`, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message)
                this.reloadData()
                this.closeModalFiles()

                toast("Archivos cargados!!!", {position:"top-right", type:"success"})
            })
            .catch(e => toast(e.message, {position:"top-right", type:"error"}))
            .finally(() => this.setState({ loader: false }))

    }

    reloadData = () => {
        this.props.refresh && this.props.refresh()
    }

    newLabel = (date) => {

        const current = new Date().getTime()
        const created = new Date(date).getTime()
        const difference = current - created;
        let totalDay = Math.ceil( difference / (1000 * 3600 * 24) )

        return totalDay <=2 ? <Badge bg="warning"><small>nuevo</small></Badge>: "";
    }

    render() {

        const row = this.status(this.props.status);
        const buttonsReady = Object.keys(this.state.errors).length > 0

        return (

            <div key={this.props.id} className={`card-content ${row.className} `}>

                <div>{this.newLabel(this.props.data?.created_at)}</div>

                <div className="d-flex justify-content-between mb-2">

                    <div className="d-flex h5 icon-files justify-content-around">


                        {
                            this.props.permission && this.props.permission >= 2 ? (

                                this.props.files?.length <= 0 ? <AiFillFileAdd onClick={this.openModalFiles} className="icon-disabled pointer" /> : ""
                            )
                                : ""
                        }
                        {

                            this.props.files?.length > 0 ? <File id={this.props.data.id} type="invoice" /> : ""

                        }
                    </div>

                    <ButtonsActionCard
                        status={this.props.data.status && this.props.data.status}
                        permission={this.props.permission && this.props.permission}
                        type={this.handleClickAction}
                    />
                </div>

                {
                    this.props.head &&
                    <>

                        <div className="align-content-center" >
                            <div style={{ fontSize: ".6rem" }}>  {`${this.props.text1} `}</div>
                        </div>
                    </>
                }

                <div className="d-flex justify-content-between">
                    <div>
                        <div className="d-flex justify-content-between flex-row">
                            <div className="fw-bold">{this.props.title}</div>

                        </div>

                        <div><BsDropletHalf /> {numberFormat(this.props.data?.quantity)} L</div>

                    </div>


                    <div className="d-flex justify-content-end mb-3">
                        <div className="me-2" style={{ maxWidth: '50%' }}><small>{shortName(this.props.data?.operator)}</small></div>
                        <div ><FaTruckMoving /> <GoArrowRight /></div>
                        <div><small>{this.props.data?.code}</small></div>
                    </div>


                </div>


                <div>
                    <div className="d-flex justify-content-between flex-row-reverse align-items-center">

        
                        { statusDelivery(this.props.status) }

                        <div className="small text-secondary"> {this.props.time} </div>

                    </div>
                </div>


                <Modal show={this.state.modal} centered="true" onShow={this.validation}>
                    <Form className="form-style">

                        <fieldset disabled={this.state.loader}>

                            <Form.Group>
                                <Form.Label><ImFilePdf/> Pdf</Form.Label>
                                <Form.Control name="pdf" type="file" onChange={this.onChangeFileHandler}
                                    className={`${this.state.errors.pdf && 'border-red'}`} />

                                {this.state.errors.pdf && <p className="input-message-danger" >{this.state.errors.pdf}</p>}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>XML</Form.Label>
                                <Form.Control name="xml" type="file" onChange={this.onChangeFileHandler}
                                    className={`${this.state.errors.xml && 'border-red'}`} />
                                {this.state.errors.xml && <p className="input-message-danger" >{this.state.errors.xml}</p>}
                            </Form.Group>

                        </fieldset>


                    </Form>

                    <Modal.Footer>

                        <Button variant="danger"  onClick={() => this.setState({ modal: false })}> <GiCancel/> Cancelar </Button>
                        <Button onClick={this.uploadFiles} disabled={buttonsReady}> {this.state.loader && <Loading />} <FaCloudUploadAlt/>   Subir</Button>


                    </Modal.Footer>


                </Modal>



            </div>


        )
    }


}


export default CardTransportation;

export { priceFormat };



