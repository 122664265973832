import { useContext, useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { Loading } from "../Components/Loading";
import TankList from "../Components/unities/TankList";
import UnitiesList from "../Components/unities/UnitiesList";
import SessionContext from "../context/sessionContext";
import { getPermissions } from "../Services/getPermissions";
import { httpHelper } from "../Services/HttpHelper";


const UnitiesPage = () => {


    const [data, setData] = useState({ unities: [], tanks: [] })
    const [loader, setLoader] = useState(false)
    const [permission, setPermission] = useState({ access: false, permission: 0 })
    const context = useContext(SessionContext)
    const [alert, setAlert] = useState({ show: false, message: {} })

    useEffect(() => {

        getData()

    }, [])

    useEffect(() => {
        setPermission(getPermissions(context.user.access, "unitiespage"))
    }, [context.user])

    const getData = () => {

        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY }
        }
        setLoader(true)
        httpHelper().get(`${process.env.REACT_APP_HOST}unity`, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message)
                setData({ unities: r.unities, tanks: r.tanks })
            })
            .catch(e => console.log(e.message))
            .finally(() => setLoader(false))

    }

    const refreshData = () => {
        getData()
    }

    const showAlert = (variant, text) => {
        setAlert({ show: true, message: { variant: variant, text: text } })
        setTimeout(() => setAlert({ show: false, message: {} }), 2000)
    }

    return (
        <div>

            <Alert show={alert.show} variant={alert.message.variant && alert.message.variant}>
                {alert.message.text && alert.message.text}
            </Alert>

            {
                loader ? <Loading /> :
                    <>
                        <UnitiesList data={data.unities} permission={permission.permission} load={refreshData} alert={showAlert} />

                        <TankList data={data.tanks} permission={permission.permission} load={refreshData} alert={showAlert} />
                    </>
            }

        </div>
    )


}


export default UnitiesPage;