import React, { useEffect, useState } from 'react';
import { BsDroplet } from 'react-icons/bs';
import { Loading } from '../Loading';

import CardTansportation from "./CardTansportation";



const TransportationList = (props) => {

    const [filtered, setFiltered] = useState([])

    useEffect(() => {

        if (props.filter) {
            setFiltered(findItems(props.list, props.filter))
        } else {
            setFiltered(props.list)
        }

    }, [props])

    const findItems = (list, filter) => {
        const f = filter.toLowerCase();
        return list.filter(item => item.code.toLowerCase().includes(f) ||
            item.operator.toLowerCase().includes(f) ||
            item.product.toLowerCase().includes(f) ||
            item.created_at.toLowerCase().includes(f) 
        )
    }


    const handleData = (item) => {
        props.handler && props.handler(item)
    }

    const handlerAction = (item, action) => {
        item.action = action
        props.action && props.action(item, action)
    }

    const reload = () => {
        props.refresh && props.refresh()
    }

    return (

        <div>

            {
                filtered?.length > 0 ?

                    props.loader && props.loader ?
                        <Loading />
                        :
                        <div className="dashboard-grid">
                            {filtered?.map(item =>
                                <CardTansportation
                                    permission={props.permission && props.permission}
                                    data={item}
                                    files={item.files && item.files}
                                    id={item.id}
                                    title={item.product}
                                    origin={item.origin}
                                    destiny={item.destiny}
                                    status={item.status}
                                    time={item.created_at}
                                    expenses={item.expenses}
                                    refresh={reload}
                                    handler={handleData}
                                    action={handlerAction}

                                />)}
                        </div>

                    :
                    <div className="d-flex justify-content-center align-items-center " style={{ minHeight: "450px" }}>

                        No hay elementos en la lista<BsDroplet className="h2" />
                    </div>
            }




        </div>
    );
}

export default TransportationList;