import React, { Component } from 'react';
import ButtonsAction from "./ButtonsAction";

class RowDataTable extends Component {

    clickAction = (type) => {
        this.props.action(this.props.id, type, this.props.item)
    }

    render() {
        return (
            <tr >

                {this.props.columns.map((value, index) => (
                    <td style={{fontSize:'.9rem', color:'#474747'}} >
                        <div className="h-100">
                        {
                            value.style ? value.style(this.props.item) : this.props.item[value.field]
                        }
                        </div>
                        
                    </td>
                ))}

                <td className="action"> {<ButtonsAction permission={this.props.permission} type={this.clickAction}
                    stations={this.props.stations} status={this.props.item?.status}
                />} </td>


            </tr>
        );
    }
}

export default RowDataTable;