import { useEffect, useState } from "react"
import DataTable from "../DataTable"
import { Button, Modal, Form } from "react-bootstrap"
import { httpHelper } from "../../Services/HttpHelper"
import Avatar from "../Avatar"
import { buttonTagAction } from "../../utils/ButtonTagAction"
import DialogDetail from "../DialogDetail"
import { toast } from "react-toastify"
import { Loading } from "../Loading"


const Stations = (props) => {

    const regexCode = /^[A-Za-z]([A-Za-z0-9])*$/
    const regexAddress = /^[A-Za-zÁ-ü.0-9]([A-Za-z0-9Á-ü-\s.,]){4,250}$/
    const regexAlias = /^[A-Za-zÁ-ü.]([A-Za-z0-9Á-ü-\s.]){4,150}$/
    const station = { code: "", address: "", alias: "", action: "create" }
    const defaultDetail = { show: false, data: {} }
    // const regexDigit = /^[1-9][0-9]{0,10}$/

    const [modal, setModal] = useState(false)
    const [form, setData] = useState(station)
    const [stations, setStations] = useState([])
    const [errors, setErrors] = useState({})
    const [client, setClient] = useState(0)
    const [loader, setLoader] = useState(false)
    const [detailModal, setDetailModal] = useState(defaultDetail)


    useEffect(() => {
        props.data && setClient(props.data.id)
    }, [props])

    //todo issue update
    useEffect(() => {

        if (props.clients) {
            const s = props.clients.find(c => c.id === client)
            setStations(s !== undefined ? s.stations : [])
        }

    }, [props.clients, client])


    useEffect(() => {
        validation()
        // eslint-disable-next-line
    }, [form])

    const resetFields = () => {
        setData(station)
        validation()
    }

    const styleAvatar = (value) => {
        return <Avatar value={value} />
    }

    const columnsStation = [
        { field: "img", title: "", style: styleAvatar },
        { field: "code", title: "Code" },
        { field: "address", title: "Dirección" },
        { field: "alias", title: "Alias" },
        { field: "client", title: "Cliente" }
    ]

    const clickNew = () => {
        setModal(true)
    }

    const handlerChange = (e) => {
        const { name, value } = e.target
        setData({
            ...form,
            [name]: value
        })
    }
    const validation = () => {
        const errors = {}
        if (!regexCode.test(form.code)) errors.code = "Codigo no valido"
        if (!regexAddress.test(form.address)) errors.address = "Dirección no valido"
        if (!regexAlias.test(form.alias)) errors.alias = "Alias no valido"
        setErrors(errors)
    }

    const action = (id, action, item) => {
        if (action === 'detail') {
            setDetailModal({ show: true, data: item })
        } else {
            setData(item)
            setModal(true)
        }

    }

    const handlerClick = () => {
        const action = form.action
        if (action === 'create') {
            create()
        }
        if (action === 'edit') {
            update()
        }
        if (action === 'delete') {
            del()
        }

    }

    const refres = () => {
        props.reload()
    }

    const create = () => {

        if (Object.keys(errors).length > 0) {
            toast("formulario no válido", {position:"top-right", type:"error"})
            return
        }

        const data = { ...form }
        data.id_client = client

        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: JSON.stringify(data)
        }
        setLoader(true)
        httpHelper().post(`${process.env.REACT_APP_HOST}station`, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message)
                toast("Creado!!", {position:"top-right", type:"success"})
                resetFields()
                refres()

                setTimeout(() => {
                    setModal(false)
                }, 1500);
            })
            .catch(e => toast(e.message, {position:"top-right", type:"error"}))
            .finally(() => setLoader(false))
    }
    const update = () => {

        if (Object.keys(errors).length > 0) {
            toast("formulario no válido", {position:"top-right", type:"error"})
            return
        }

        const data = { ...form }
        data.id_client = client

        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: JSON.stringify(data)
        }
        setLoader(true)
        httpHelper().put(`${process.env.REACT_APP_HOST}station`, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message)
                toast("Actualizado!!", {position:"top-right", type:"success"})
                resetFields()
                refres()

                setModal(false)

            })
            .catch(e => toast(e.message, {position:"top-right", type:"error"}))
            .finally(() => setLoader(false))

    }
    const del = () => {

        if (Object.keys(errors).length > 0) {
            toast("formulario no válido", {position:"top-right", type:"error"})
            return
        }

        const data = { ...form }
        data.id_client = client

        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: JSON.stringify(data)
        }
        setLoader(true)
        httpHelper().del(`${process.env.REACT_APP_HOST}station`, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message)
                toast("Eliminado!!", {position:"top-right", type:"success"})
                setModal(false)
                refres()
            })
            .catch(e => toast(e.message, {position:"top-right", type:"error"}))
            .finally(() => setLoader(false))

    }

    const closeModal = () => {
        setModal(false)
        setData(station)
    }


    return (
        <div className="card-content">
            <h2 className="d-flex justify-content-between align-items-center" >Estaciones
                {
                    props.permission && props.permission >= 2 ? <Button className="m-0" onClick={clickNew}>Nuevo+</Button> : ""
                }
            </h2>
            <DataTable data={stations}
                columns={columnsStation}
                action={action}
                permission={props.permission && props.permission} />


            <Modal show={modal} onShow={validation}>

                <Modal.Header>
                    <h2>Estacion</h2>

                </Modal.Header>
                <Modal.Body>

                    {
                        form.action && form.action !== 'delete' ?
                            <Form className="form-style">
                                <fildset disabled={loader}>
                                    <Form.Group>
                                        <Form.Label>Code</Form.Label>
                                        <Form.Control name="code" value={form.code} onChange={handlerChange} onBlur={handlerChange} className="text-uppercase" />
                                        {errors.code && <p className="input-message-danger">{errors.code}</p>}
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Dirección</Form.Label>
                                        <Form.Control name="address" value={form.address} onChange={handlerChange} onBlur={handlerChange} />
                                        {errors.address && <p className="input-message-danger">{errors.address}</p>}
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Alias</Form.Label>
                                        <Form.Control name="alias" value={form.alias} onChange={handlerChange} onBlur={handlerChange} />
                                        {errors.alias && <p className="input-message-danger">{errors.alias}</p>}
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Cliente</Form.Label>
                                        <Form.Select name="id_client" value={client}
                                            onChange={handlerChange}
                                            onBlur={handlerChange} disabled="true" >

                                            <option value="0">---</option>
                                            {
                                                props.clients &&
                                                props.clients.map(item => (
                                                    <option value={item.id}>{item.name}</option>
                                                ))
                                            }

                                        </Form.Select>
                                        {errors.id_client && <p className="input-message-danger">{errors.id_client}</p>}
                                    </Form.Group>

                                </fildset>
                            </Form>
                            :
                            <div>
                                ¿ Eliminar a " {form.code && form.code} " ?
                            </div>
                    }



                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" onClick={closeModal} disabled={loader}>Cancelar</Button>
                    <Button onClick={handlerClick} disabled={loader} >  {loader&&<Loading/>} {buttonTagAction(form.action)}</Button>
                </Modal.Footer>
            </Modal>

            <DialogDetail show={detailModal.show} data={detailModal.data} onHide={ ()=> setDetailModal(defaultDetail) }/>
        </div>
    )
}

export default Stations;
