import { FaUserCircle } from "react-icons/fa"


const Avatar = (props) => {

    const img = (value) =>{
        return value.img != null ? <img src={value.img} alt="avatar" /> : <FaUserCircle/>
    }

    const status = (value) =>{
        const c = {
            0: `right-bottom red`,
            1: `right-bottom green`
        }

        return c[value.status]
    }

    return(        
        <div className="avatar">
            {img(props.value)}
            <span className={status(props.value)}></span>
        </div>
    )

}

export default Avatar;