import { useEffect, useState } from "react"
import { Form, Modal, Button, Badge } from "react-bootstrap"
import { FaUser, FaUserPlus } from "react-icons/fa"
import { httpHelper } from "../../Services/HttpHelper"
import { buttonTagAction } from "../../utils/ButtonTagAction"
import DataTable from "../DataTable"
import {toast} from "react-toastify"



const Transport = (props) => {

    const styleStatus = (value) => {
        return value.status === 1 ? <Badge pill bg="success">{value.id}</Badge> : <Badge pill bg="danger">{value.id}</Badge>
    }

    const clickOperators = (value) => {
        props.onOperator&&props.onOperator(value)
    }

    const operatorCounter = (value) => {
        const count = value.operators?.length || 0

        return count > 0 ? <Badge pill className="pointer" onClick={()=>clickOperators(value)}><FaUser/>{count}</Badge>: "";
    }

    const columns = [
        { field: "id", title: "ID", style: styleStatus },
        { field: "name", title: "Nombre" },
        { title:  <FaUser/> , style: operatorCounter}
    ]

    const regexName = /^[A-Za-zÁ-ü.]([A-Za-z0-9Á-ü-\s.]){2,150}$/

    const defaultData = { name: "", action: "create" }

    const [modal, setModal] = useState(false)
    const [errors, setErrors] = useState({})
    const [loader, setLoader] = useState(false)
    const [modalData, setModalData] = useState(defaultData)
    

    const action = (id, action, item) => {
        if (action !== 'detail') {
            setModal(true)
            setModalData(item)
        }

    }

    const performClick = () => {
        const action = modalData.action
        if (action === 'create') { create() }
        if (action === 'edit') { update() }
        if (action === 'delete') { del() }
        if (action === 'detail') { }
    }

    const validate = () => {
        const errors = {}
        if (!regexName.test(modalData.name)) errors.name = "El nombre no puede ser vacío"

        setErrors(errors)
    }

    useEffect(() => {
        validate()
        // eslint-disable-next-line
    }, [modalData])

    const create = () => {

        if (Object.keys(errors).length > 0) {
            toast("formulario no válido", {position:"top-right", type:"error"})
            return;
        }
        setLoader(true)
        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: JSON.stringify(modalData)
        }
        httpHelper().post(`${process.env.REACT_APP_HOST}transport`, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message)
                setModal(false)
                toast("Creado!!!", {position:"top-right", type:"success"})
                reload()
            })
            .catch(e => toast(e.message, {position:"top-right", type:"error"}))
            .finally(() => { setLoader(false) })

    }

    const update = () => {
        if (Object.keys(errors).length > 0) {
            toast("formulario no válido", {position:"top-right", type:"error"})
            return;
        }
        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: JSON.stringify(modalData)
        }
        setLoader(true)
        httpHelper().put(`${process.env.REACT_APP_HOST}transport`, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message)
                setModal(false)
                toast("Actualizado!!!", {position:"top-right", type:"success"})
                reload()
            })
            .catch(e => toast(e.message, {position:"top-right", type:"error"}))
            .finally(() => { 
                setLoader(false)
            })

    }

    const del = () => {


        if (Object.keys(errors).length > 0) {
            toast("formulario no válido", {position:"top-right", type:"error"})
            return;
        }
        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: JSON.stringify(modalData)
        }
        setLoader(true)
        httpHelper().del(`${process.env.REACT_APP_HOST}transport`, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message)
                setModal(false)
                toast("Baja !!!", {position:"top-right", type:"success"})
                reload()

            })
            .catch(e => toast(e.message, {position:"top-right", type:"error"}))
            .finally(() => { setLoader(false) })

    }

    const reload = () => {
        props.reload && props.reload()
    }


    const closeModal = () => {
        setModal(false)
        setModalData(defaultData)
    }



    const clickNew = () => {
        setModal(true)
    }

    const handlerChange = (e) => {
        const { name, value } = e.target

        setModalData({
            ...modalData,
            [name]: value
        })

    }


    return (

        <>
            <div className="card-content">
                <h3 className="d-flex justify-content-between"> Transportista {props.permission && props.permission >= 2 ? <Button size="sm" className="m-0" onClick={clickNew} >Nuevo <FaUserPlus/></Button> : ""} </h3>

                <DataTable data={props.data && props.data} columns={columns}
                    permission={props.permission && props.permission}
                    action={action}
                />


                <Modal show={modal} centered="true">

                    <Modal.Body>

                        {
                            modalData.action !== 'delete'
                                ?
                                <Form>
                                    <fieldset disabled={loader}>

                                        <Form.Group>
                                            <Form.Label>Nombre</Form.Label>
                                            <Form.Control name="name" value={modalData.name && modalData.name} onChange={handlerChange} onBlur={handlerChange} />
                                            {
                                                errors.name && <p className="input-message-danger" > {errors.name} </p>
                                            }
                                        </Form.Group>


                                    </fieldset>
                                </Form>
                                :
                                <div>¿Eliminar {modalData.name}?</div>

                        }



                    </Modal.Body>

                    <Modal.Footer>
                        <Button disabled={loader} variant="danger" onClick={closeModal}>Cancelar</Button>
                        <Button disabled={loader} onClick={performClick}> {buttonTagAction(modalData.action && modalData.action)} </Button>
                    </Modal.Footer>
                </Modal>

            </div>



        </>

    )


}

export default Transport;