import DataTable from "../DataTable"
import { Button} from "react-bootstrap"
import { useEffect, useState } from "react"
import { Modal, Form } from "react-bootstrap"

import { httpHelper } from "../../Services/HttpHelper"
import { buttonTagAction } from "../../utils/ButtonTagAction"
import { numberFormat, styleActive } from "../../utils/Utils"
import {toast} from "react-toastify"


const TankList = (props) => {


    const regexDigit = /^[1-9]([0-9]?){1,10}$/
    const regexPlates = /^[A-Za-z1-9]([A-Za-z-0-9]){3,50}$/
    const regexName = /^[A-Za-zÁ-ü]([A-Za-z0-9Á-ü-\s.]){4,50}$/
    const capacityStyle = (value) => {
        return numberFormat(value.capacity) + "L"
    }

    const columns = [
        { field: "status", title: "", style:styleActive },
        { field: "brand", title: "Marca" },
        { field: "plates", title: "Placas" },
        { field: "observation", title: "observación" },
        { field: "capacity", title: "Capacidad", style:capacityStyle }

    ]
    const formDefault = { name: "", brand: "", plates: "", observation: "", capacity: 0, action: "create" }

    const [modal, setModal] = useState(false)
    const [form, setForm] = useState(formDefault)
    const [errors, setErrors] = useState({})
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        validate()
        // eslint-disable-next-line
    }, [form])

    const action = (id, action, item) => {
        setForm(item)
        setModal(true)

    }

    

    const newClick = () => {
        setModal(true)
    }
    const closeModal = () => {
        setModal(false)
        setForm(formDefault)
    }

    const performClick = () => {
        const action = form.action
        if (action === 'create') create();
        if (action === 'edit') update();
        if (action === 'delete') del();
    }

    const create = () => {

        if (Object.keys(errors).length > 0) {
            toast("formulario no válido", {position:"top-right", type:"error"})
            return
        }
        setLoader(true)
        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: JSON.stringify(form)
        }
        httpHelper().post(`${process.env.REACT_APP_HOST}tank`, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message)
                toast("Creado!!", {position:"top-right", type:"success"})

                refresh()
                closeModal()
            })
            .catch(e => {
                toast(e.message, {position:"top-right", type:"error"})
                closeModal()
            })
            .finally(() => setLoader(false))

    }

    const update = () => {
        if (Object.keys(errors).length > 0) {
            toast("formulario no válido", {position:"top-right", type:"error"})
            return
        }
        setLoader(true)
        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: JSON.stringify(form)
        }
        httpHelper().put(`${process.env.REACT_APP_HOST}tank`, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message)
                toast("Actualizado!!", {position:"top-right", type:"success"})
                refresh()
                closeModal()
            })
            .catch(e => {
                closeModal()
                toast(e.message, {position:"top-right", type:"error"})
            })
            .finally(() => setLoader(false))

    }

    const del = () => {

        setLoader(true)
        const opt = {
            headers: { authorization: process.env.REACT_APP_API_KEY },
            body: JSON.stringify(form)
        }
        httpHelper().del(`${process.env.REACT_APP_HOST}tank`, opt)
            .then(r => {
                if (!r.response) throw new Error(r.message)
                toast("Eliminado!!", {position:"top-right", type:"success"})
                refresh()
                closeModal()
            })
            .catch(e => {
                toast(e.message, {position:"top-right", type:"error"})
                closeModal()
            })
            .finally(() => setLoader(false))

    }

    const refresh = () => {
        props.load && props.load()
    }

    const handlerChange = (e) => {
        const { value, name } = e.target

        setForm({
            ...form,
            [name]: value
        })
    }

    const validate = () => {
        const errors = {}
        if (!regexName.test(form.name)) errors.name = "Requerido";
        if (!regexName.test(form.brand)) errors.brand = "Requerido";
        if (!regexPlates.test(form.plates)) errors.plates = "Requerido";
        if (!regexDigit.test(form.capacity)) errors.capacity = "Requerido";
     

        setErrors(errors)
    }

    return (
        <div className="card-content">
            <h2>Tanques {props.permission && props.permission >= 2 ? <Button className="m-0" size="sm" onClick={newClick}>Nuevo+</Button> : ""}</h2>

            <DataTable columns={columns} permission={props.permission && props.permission} action={action}
                data={props.data && props.data} />


            <Modal show={modal} onShow={validate} centered="true">


                <Modal.Body className="p-4">

                    {
                        form.action !== 'delete' ?
                            <Form className="form-style" >
                                <fieldset disabled={loader}>

                                    <Form.Group>
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control name="name" className="text-uppercase" value={form.name} onChange={handlerChange} onBlur={handlerChange} />
                                        {errors.name && <p className="input-message-danger"> {errors.name} </p>}
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Marca</Form.Label>
                                        <Form.Control name="brand" value={form.brand} onChange={handlerChange} onBlur={handlerChange} />
                                        {errors.brand && <p className="input-message-danger"> {errors.brand} </p>}
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Placas</Form.Label>
                                        <Form.Control name="plates" value={form.plates} onChange={handlerChange} onBlur={handlerChange} />
                                        {errors.plates && <p className="input-message-danger"> {errors.plates} </p>}
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Capacidad</Form.Label>
                                        <Form.Control name="capacity" type="number" value={form.capacity} onChange={handlerChange} onBlur={handlerChange} />
                                        {errors.capacity && <p className="input-message-danger"> {errors.capacity} </p>}
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Observación</Form.Label>
                                        <Form.Control name="observation" as="textarea" value={form.observation} onChange={handlerChange} onBlur={handlerChange} />
                                        {errors.observation && <p className="input-message-danger"> {errors.observation} </p>}
                                    </Form.Group>

                                </fieldset>
                            </Form> :
                            <div>
                                ¿Eliminar a {form.name} ?
                            </div>
                    }

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" onClick={closeModal}>Cancelar</Button>
                    <Button onClick={performClick} >{buttonTagAction(form.action)}</Button>
                </Modal.Footer>
            </Modal>

        </div>
    )


}


export default TankList;